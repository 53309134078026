import React, { useEffect } from 'react';
import { Box, Grid, GridItem, Text, Input } from '@chakra-ui/react';
import InputField from '../form/inputField';
import useFormValidation from '../../hooks/useFormValidation';
import { get } from '../../services/api';

const CompanyDetails = ({ userId }) => {
    const { values, errors, setInitialFormValues } = useFormValidation({
      companyName: '',
      numberOfEmployees: '',
      industryType: '',
    });
  
    useEffect(() => {
        if (userId !== undefined && userId) {
            const fetchCompanyData = async () => {
                try {
                    const response = await get(`users/${userId}`);
                    const userData = response.data.data;
                    const initialCompanyData = {
                        companyName: userData.companyName,
                        numberOfEmployees: userData.companySize,
                        industryType: userData.industryType,
                    };
                    setInitialFormValues(initialCompanyData);
                } catch (error) {
                    console.error('Error fetching company details:', error);
                }
            };
            fetchCompanyData();
        }
    }, [userId]);
  
    return (
      <>
        <Text fontSize="20px" fontWeight="600" color="black" mb="8px">
          Company Details
        </Text>
        <Box
          w="100%"
          bg="#F5F8FF"
          p="20px"
          h="fit-content"
          border="1px solid #D1E0FF"
          borderRadius="12px"
          mb={6}
        >
          <Box>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
              <GridItem w="100%" h="fit-content">
                <InputField
                    label="Name of company"
                    value={values.companyName}
                    error={errors.companyName}
                    // onChange={(e) => handleChange('companyName', e.target.value)}
                    placeholder="Enter Company name"
                    isReadOnly
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <InputField
                    label="Number of the Employees in company"
                    type="number"
                    value={values.numberOfEmployees}
                    error={errors.numberOfEmployees}
                    // onChange={(e) => handleChange('numberOfEmployees', e.target.value)}
                    placeholder="Enter size of the company"
                    isReadOnly
                  />
              </GridItem>
            </Grid>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6} mt={6}>
              <GridItem w="100%" h="fit-content">
                <InputField
                    label="Industry type"
                    value={values.industryType}
                    error={errors.industryType}
                    // onChange={(e) => handleChange('industryType', e.target.value)}
                    placeholder="Please enter industry type"
                    isReadOnly
                />
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </>
    );
};
  
export default CompanyDetails;