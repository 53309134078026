import '../../App.css';
import React from "react";
import { ChakraProvider, Text, Box, Heading, Textarea, Button, Icon } from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import { ReactComponent as circleCloseIcon } from '../../assets/icons/circleCloseIcon.svg';
import useFeedbackForm from '../../hooks/useFeedbackFormValidation';
import InputField from '../../components/form/inputField';
import TextAreaField from '../../components/form/textAreaField';

function Feedback() {
  const {
    subject,
    setSubject,
    description,
    setDescription,
    file,
    errors,
    fileInputRef,
    handleFileChange,
    handleFileButtonClick,
    removeFile,
    handleBlur,
    handleSubmit,
    isFormInvalid,
  } = useFeedbackForm();

  return (
    <ChakraProvider>
        <Box display='flex' flexDirection='column' height='100%'>
        <Box flex='1' overflowY='auto' display='flex' flexDirection='column' justifyContent='space-between' w="100%" h='fit-content'>
          <Box px={[4, 6, 10]} py={10}>
          <Box>
            <Heading size='md' fontSize='24px' fontWeight='700' mb={4}>Feedback</Heading>
          </Box>
          <Box w='100%' bg='#F5F8FF' p={[4, 6, 8]} border='1px solid #D1E0FF' borderRadius='12px'>
            <InputField
              label="Subject of the feedback"
              value={subject}
              error={errors.subject}
              onChange={(e) => setSubject(e.target.value)}
              onBlur={handleBlur('subject')}
              placeholder="Write the subject of the feedback here"
              isRequired
            />
            <TextAreaField
              label="Description"
              value={description}
              error={errors.description}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={handleBlur('description')}
              placeholder="Write the decription of the feedback here"
              isRequired
            />
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              hidden
              multiple
              accept=".jpeg, .png, .jpg, .gif"
              onChange={handleFileChange}
            />
            <Button leftIcon={<AttachmentIcon />} variant='outline' bg="white" borderColor='#2970FF' color='#2970FF' _hover={{ bg: 'white' }} mb={3} onClick={handleFileButtonClick}>
              Attach file
            </Button>
            {file.length > 0 && (
              <Box display='flex' flexDirection='column' mb={2}>
                <Text fontSize='14px' style={{ color: '#111927', fontWeight: '300' }} pr={2}>Attached Files</Text>
                <Box p={3} display='flex' flexWrap='wrap' gap={2} maxWidth='100%'>
                  {file.map((item, index) => (
                    <Box key={index} display='flex' alignItems='center' py='3px' px='10px' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' border='1px solid #D1E0FF' borderRadius='5px' bg='#D1E0FF'>
                      <Text fontSize='12px' style={{ color: '#004EEB', fontWeight: '400' }} pr='2'>{item.name}</Text>
                      <Icon as={circleCloseIcon} w="16px" h="16px" sx={{ cursor: 'pointer' }} onClick={() => removeFile(item.name)}/>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Text fontSize='14px' color='gray' style={{ color: '#6C737F', fontWeight: '500' }}>Note: Please include your attachments in .jpeg or .png format to provide clearer explanation of your concern</Text>
          </Box>
        </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' py={4} px={5} w='100%' bg='white' borderTop='1px solid #D1E0FF'>
          <Button
            bg={!isFormInvalid() ? "#2970FF" : "#F04438"}
            color='white'
            variant='solid'
            width={{ base: 'fit-content', md: '15%', lg: '18%', xl: '15%' }}
            _hover={{ bg: !isFormInvalid() ? '#7044c4' : '#F04438' }}
            onClick={handleSubmit}
            isDisabled={isFormInvalid()}
            maxW="100%"
          >
            Send Feedback
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Feedback;
