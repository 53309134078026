import '../../App.css';
import React from "react";
import { ChakraProvider, Text, Box, Heading, Grid, GridItem, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Button, useDisclosure, Flex, useToast } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';
import { get, remove } from '../../services/api';
import ModalAddIndustry from '../../components/modals/addIndustryModal';
import ModalEditIndustry from '../../components/modals/editIndustryModal';
import ModalAddSection from '../../components/modals/addSectionModal';
import DeleteConfirmationModal from '../../components/modals/deleteConfirmModal';
import Section from './section';

function AdminTool() {
  // const [expandedSection, setExpandedSection] = React.useState(null);
  const [expandedSections, setExpandedSections] = React.useState([]);
  const [selectedOptionIndustry, setSelectedOptionIndustry] = React.useState(null);
  const [industryOptions, setIndustryOptions] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
  const menuButtonRef = React.useRef();
  const toast = useToast();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [deleteAction, setDeleteAction] = React.useState(null);

  const handleDeleteClick = (type, industryTypeId, sectionId = null, assertionId = null, updateAssertionsLocally = null) => {
    setDeleteAction({ type, industryTypeId, sectionId, assertionId, updateAssertionsLocally });
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (deleteAction.type === 'section') {
        await remove(`/reportmanagement/industry/${deleteAction.industryTypeId}/section/${deleteAction.sectionId}`);
        setSections(prevSections => prevSections.filter(section => section.sectionId !== deleteAction.sectionId));
        toast({
          title: 'Success',
          description: 'Section deleted successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else if (deleteAction.type === 'assertion') {
        await remove(`/reportmanagement/industry/${deleteAction.industryTypeId}/section/${deleteAction.sectionId}/assertion/${deleteAction.assertionId}`);
        setSections(prevSections =>
          prevSections.map(section => {
            if (section.sectionId === deleteAction.sectionId) {
              return {
                ...section,
                assertions: section.assertions.filter(
                  assertion => assertion.assertionId !== deleteAction.assertionId
                ),
              };
            }
            return section;
          })
        );
        toast({
          title: 'Success',
          description: 'Assertion deleted successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } 
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting item:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the item.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  React.useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);

  const {
    isOpen: isIndustryModalOpen,
    onOpen: onIndustryModalOpen,
    onClose: onIndustryModalClose,
  } = useDisclosure();

  const {
    isOpen: isSectionModalOpen,
    onOpen: onSectionModalOpen,
    onClose: onSectionModalClose,
  } = useDisclosure();

  const {
    isOpen: isEditIdustryModalOpen,
    onOpen: onEditIdustryModalOpen,
    onClose: onEditIdustryModalClose,
  } = useDisclosure();

  const fetchSections = React.useCallback(async () => {
    try {
      if (selectedOptionIndustry && selectedOptionIndustry.id) {
        const response = await get(`/reportmanagement/industry/${selectedOptionIndustry.id}/section`);
        const fetchedSections = response.data.data.section.map(section => ({
          sectionId: section.sectionId,
          name: section.name,
          assertions: section.assertions.map(assertion => ({
            assertionId: assertion.assertionId,
            assertionName: assertion.assertionQuestion
          }))
        }));
        setSections(fetchedSections);
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  }, [selectedOptionIndustry]);

  const fetchIndustryTypes = async () => {
    try {
      const response = await get('/reportmanagement/industry');
      const industryTypes = response.data.data.map(item => ({
        id: item.industryTypeId,
        type: item.industryType,
      }));
      setIndustryOptions(industryTypes);
      if (industryTypes.length > 0) {
        setSelectedOptionIndustry(industryTypes[0]);
      }
    } catch (error) {
      console.error('Error fetching industry types:', error);
    }
  };

  const toggleSection = (sectionId) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId)
        ? prev.filter(id => id !== sectionId)
        : [...prev, sectionId]
    );
  };

  React.useEffect(() => {
    fetchIndustryTypes();
  }, []);

  React.useEffect(() => {
    fetchSections();
  }, [fetchSections]);

  React.useEffect(() => {
    setExpandedSections([]);
  }, [selectedOptionIndustry]);

  return (
    <>
      <ChakraProvider>
        <Box px={[4, 6, 10]} py={10} w="100%" h="fit-content" >
          <Box>
            <Heading size="md" fontSize={{ base: '20px', md: '24px' }} fontWeight="700" mb={2}>
              Admin Tools
            </Heading>
          </Box>
          <br />
          <Box
            w="100%"
            bg="#F5F8FF"
            p={{ base: '8px', sm: '10px' }}
            h="fit-content"
            border="1px solid #D1E0FF"
            borderRadius="12px"
          >
            <Box>
              <Grid templateColumns={{ base: '1fr', sm: 'repeat(7, 1fr)' }} gap={{ base: 2, sm: 6 }}>
                <GridItem w='100%' h="fit-content" colSpan={5}>
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          ref={menuButtonRef}
                          w='97%'
                          bg='white'
                          border='1px solid #F3F4F6'
                          fontWeight='400'
                          _hover={{ bg: 'white', borderColor: '#D1E0FF' }}
                          _active={{ bg: 'white', borderColor: '#D1E0FF' }}
                          _focus={{ bg: 'white' }}
                          as={Button}
                          textAlign='left'
                          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                          overflow="hidden"
                        >
                          <Box
                            maxW="95%"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            {selectedOptionIndustry
                              ? selectedOptionIndustry.type
                              : 'Select an industry'}
                          </Box>
                        </MenuButton>
                        <MenuList p={2} width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} borderColor='#EFF4FF' borderRadius='12px' maxHeight="400px" overflowY="auto"
                          css={{
                            '::-webkit-scrollbar': { display: 'none' },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                          }} 
                        >
                          {industryOptions.map((option, index) => (
                            <React.Fragment key={option.id}>
                              <MenuItem
                                onClick={() => setSelectedOptionIndustry(option)}
                                borderRadius="5px"
                                bg={selectedOptionIndustry?.type === option.type ? "#EFF4FF" : "white"}
                                _hover={{ bg: "#EFF4FF" }}
                                color="#00359E"
                                textAlign="left"
                                // whiteSpace="normal"
                                // wordWrap="break-word"
                              >
                                <Flex justify="space-between" align="center" w="100%">
                                  <Box>{option.type}</Box>
                                  {selectedOptionIndustry?.type === option.type && (
                                    <Box as="span" color="#00359E" ml={2}>
                                      <CheckIcon boxSize={3} />
                                    </Box>
                                  )}
                                </Flex>
                              </MenuItem>
                              {index < industryOptions.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                            </React.Fragment>
                          ))}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <Button
                    variant="outline"
                    borderColor="#2970FF"
                    bg="white"
                    _hover={{ bg: '#2970FF', color: 'white' }}
                    color="#2970FF"
                    onClick={onEditIdustryModalOpen}
                  >
                    Edit Industry
                  </Button>
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <Button
                    bg="#2970FF"
                    color="white"
                    variant="solid"
                    _hover={{ bg: '#7044c4' }}
                    onClick={onIndustryModalOpen}
                  >
                    Add New Industry
                  </Button>
                  <ModalEditIndustry isOpen={isEditIdustryModalOpen} onClose={onEditIdustryModalClose} industryTypeId={selectedOptionIndustry?.id} IndustryType={selectedOptionIndustry?.type} refreshIndustry={fetchIndustryTypes}/>
                  <ModalAddIndustry isOpen={isIndustryModalOpen} onClose={onIndustryModalClose} refreshIndustry={fetchIndustryTypes} />
                </GridItem>
              </Grid>
            </Box>
          </Box>
          <br />
          <Box
            w="100%"
            bg="#F5F8FF"
            px={{ base: '15px', sm: '30px' }}
            py={{ base: '10px', sm: '20px' }}
            h="fit-content"
            border="1px solid #D1E0FF"
            borderRadius="12px"
          >
            <Box display="flex" justifyContent="space-between" alignItems='center' flexDirection='row'>
              <Text fontSize={{ base: '18px', sm: '20px' }} fontWeight="600" color="black">
                Sections
              </Text>
              <Button
                mt={{ base: 1, sm: 0 }}
                variant="outline"
                borderColor="#2970FF"
                bg="white"
                _hover={{ bg: '#2970FF', color: 'white' }}
                color="#2970FF"
                onClick={onSectionModalOpen}
              >
                Add New Section
              </Button>
              <ModalAddSection
                isOpen={isSectionModalOpen}
                onClose={onSectionModalClose}
                refreshSections={fetchSections}
                industryId={selectedOptionIndustry?.id}
              />
            </Box>
            {sections.map((section) => (
              <React.Fragment key={section.sectionId}>
                <br />
                <Section
                  key={section.sectionId}
                  industryTypeId={selectedOptionIndustry.id}
                  sectionId={section.sectionId}
                  name={section.name}
                  isExpanded={expandedSections.includes(section.sectionId)}
                  onExpand={() => toggleSection(section.sectionId)}
                  assertions={section.assertions}
                  refreshSections={fetchSections}
                  handleDeleteClick={handleDeleteClick}
                  updateAssertionsLocally={(updatedAssertions) => {
                    setSections(prevSections =>
                      prevSections.map(s => {
                        if (s.sectionId === section.sectionId) {
                          return { ...s, assertions: updatedAssertions };
                        }
                        return s;
                      })
                    );
                  }}
                />
              </React.Fragment>
            ))}
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onConfirm={handleConfirmDelete}
              message = "Item"
            />
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default AdminTool;
