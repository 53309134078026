import '../../App.css';
import React from "react";
import { Text, Box, Icon, Button, useDisclosure, Flex, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, EditIcon } from '@chakra-ui/icons';
import ModalAddAssertion from '../../components/modals/addAssertionModal';
import ModalEditAssertion from '../../components/modals/editAssertionModal';
import ModalEditSection from '../../components/modals/editSectionModal';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import { useSpring, animated } from '@react-spring/web';

const Section = React.memo(({
  name, isExpanded, onExpand, assertions, sectionId, industryTypeId, refreshSections, handleDeleteClick, updateAssertionsLocally
}) => {
  const { isOpen: isAssertionModalOpen, onOpen: onAssertionModalOpen, onClose: onAssertionModalClose } = useDisclosure();
  const { isOpen: isEditAssertionModalOpen, onOpen: onEditAssertionModalOpen, onClose: onEditAssertionModalClose } = useDisclosure();
  const { isOpen: isEditSectionModalOpen, onOpen: onEditSectionModalOpen, onClose: onEditSectionModalClose } = useDisclosure();
  const [selectedAssertionId, setSelectedAssertionId] = React.useState(null);
  const contentRef = React.useRef(null);
  const [height, setHeight] = React.useState(0);

  const handleEditIconClick = (assertionId) => {
    setSelectedAssertionId(assertionId);
    onEditAssertionModalOpen();
  };

  const buttonWidth = useBreakpointValue({ sm: "fit-content", md: "fit-content", xl: "fit-content" });

  React.useLayoutEffect(() => {
    if (isExpanded) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded, assertions]);

  const animationProps = useSpring({
    height: height,
    opacity: isExpanded ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <Box bg='white' p='15px' h='fit-content' border={isExpanded ? '1px solid #2970FF' : '1px solid #D1E0FF'} borderRadius='12px' onClick={onExpand} cursor='pointer'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Text fontSize='18px' fontWeight="500" color='black' mr={3}>{name}</Text>
          {isExpanded && (
            <>
              <Icon as={EditIcon} boxSize={5} color='#2970FF' cursor='pointer' onClick={(e) => { e.stopPropagation(); onEditSectionModalOpen() }} />
              <ModalEditSection
                isOpen={isEditSectionModalOpen}
                onClose={onEditSectionModalClose}
                industryTypeId={industryTypeId}
                sectionId={sectionId}
                section ={name}
                refreshSections={refreshSections}
              />
            </>
          )}
        </Box>
        <Icon as={isExpanded ? ChevronUpIcon : ChevronDownIcon} boxSize={5} cursor='pointer' onClick={onExpand} />
      </Box>
      <animated.div style={animationProps}>
        <Box ref={contentRef}>
          <br />
          {assertions.map((assertion, index) => (
            <React.Fragment key={assertion.assertionId}>
              <Box bg='white' p='15px' h='fit-content' border='1px solid #D1E0FF' borderRadius='12px' onClick={(e) => e.stopPropagation()}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='16px' fontWeight="500" color='#6C737F' mr={3}>{assertion.assertionName}</Text>
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Icon as={EditIcon} boxSize={5} color='#2970FF' cursor='pointer' mr={3} onClick={(e) => { e.stopPropagation(); handleEditIconClick(assertion.assertionId) }} />
                    {selectedAssertionId === assertion.assertionId && (
                      <ModalEditAssertion
                        isOpen={isEditAssertionModalOpen}
                        onClose={onEditAssertionModalClose}
                        industryTypeId={industryTypeId}
                        sectionId={sectionId}
                        assertionId={selectedAssertionId}
                        refreshSections={refreshSections}
                      />
                    )}
                    <Icon as={DeleteIcon} boxSize={5} color='#F04438' cursor='pointer' onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick('assertion', industryTypeId, sectionId, assertion.assertionId, updateAssertionsLocally);
                    }} />
                  </Box>
                </Box>
              </Box>
              <br />
            </React.Fragment>
          ))}
          <Box width={{ sm: "100%", md: "100%", xl: '27%' }}>
            <Flex>
              <Button variant="ghost" bg="#FEF3F2" _hover={{ bg: "#FEF3F2" }} color="#F04438" width={buttonWidth} onClick={(e) => { e.stopPropagation(); handleDeleteClick('section', industryTypeId, sectionId) }}>
                Delete section
              </Button>
              <Spacer />
              <Button bg="#2970FF" color="white" variant="solid" width={buttonWidth} _hover={{ bg: "#7044c4" }} onClick={(e) => { e.stopPropagation(); onAssertionModalOpen() }}>
                Add Assertion
              </Button>
              <ModalAddAssertion
                isOpen={isAssertionModalOpen}
                onClose={onAssertionModalClose}
                sectionId={sectionId}
                industryTypeId={industryTypeId}
                refreshSections={refreshSections}
              />
            </Flex>
          </Box>
        </Box>
      </animated.div>
    </Box>
  );
});

export default Section;