import React, { useEffect } from 'react';
import InputField from '../form/inputField';
import { Box, Grid, GridItem, Text, Input } from '@chakra-ui/react';
import useFormValidation from '../../hooks/useFormValidation';
import { get } from '../../services/api';

const UserDetails = ({ userId }) => {
    const { values, errors, setInitialFormValues } = useFormValidation({
      name: '',
      phoneNumber: '',
      email: '',
    });
  
    useEffect(() => {
        if (userId !== undefined && userId) {
            const fetchUserData = async () => {
                try {
                    const response = await get(`users/${userId}`);
                    const userData = response.data.data;
                    const initialUserData = {
                        name: userData.userName,
                        phoneNumber: userData.phoneNumber,
                        email: userData.emailId,
                      };
                      setInitialFormValues(initialUserData);
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            };
            fetchUserData();
        }
    }, [userId]);
  
    return (
      <>
        <Text fontSize="20px" fontWeight="600" color="black" mb="8px">
          User Details
        </Text>
        <Box
          w="100%"
          bg="#F5F8FF"
          p="20px"
          h="fit-content"
          border="1px solid #D1E0FF"
          borderRadius="12px"
          mb={6}
        >
          <Box>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
              <GridItem w="100%" h="fit-content">
                <InputField
                    label="Name of user"
                    value={values.name}
                    error={errors.name}
                    // onChange={(e) => handleChange('name', e.target.value)}
                    placeholder="Name of a user"
                    isReadOnly
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <InputField
                    label="Phone Number"
                    type="number"
                    value={values.phoneNumber}
                    error={errors.phoneNumber}
                    // onChange={(e) => handleChange('phoneNumber', e.target.value)}
                    placeholder="Enter Phone number"
                    isReadOnly
                />
              </GridItem>
            </Grid>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6} mt={6}>
              <GridItem w="100%" h="fit-content">
                <InputField
                    label="Email Address"
                    value={values.email}
                    error={errors.email}
                    // onChange={(e) => handleChange('email', e.target.value)}
                    placeholder="Enter Email address"
                    isReadOnly
                />
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </>
    );
};
  
export default UserDetails;