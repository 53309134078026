import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupAxios = (logout) => {
  // Request interceptor to add token to headers
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor to handle token refresh and logout logic
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 403 && error.response.data.logout) {
        clearTokens();
        logout();
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newToken = await refreshToken(logout);
        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );
};

const refreshToken = async (logout) => {
  const storedRefreshToken = localStorage.getItem('refreshToken');
  if (storedRefreshToken) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, { refreshToken: storedRefreshToken });
      const newToken = response.data.data.token;
      storeToken(newToken, storedRefreshToken);
      return newToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      if (error.response && error.response.status === 401) {
        clearTokens();
        logout();
      }
      return null;
    }
  }
};

const storeToken = (token, refreshToken) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

const clearTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

export const get = (url, config = {}) => axiosInstance.get(url, config);
export const post = (url, data, config = {}) => axiosInstance.post(url, data, config);
export const patch = (url, data, config = {}) => axiosInstance.patch(url, data, config);
export const remove = (url, config = {}) => axiosInstance.delete(url, config);
