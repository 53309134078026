import '../../App.css';
import React from "react";
import {ChakraProvider, Text, Spinner, Box, Heading, Button, TableContainer,Table,Thead,Tbody,Tr,Th,Td,useDisclosure} from '@chakra-ui/react';
import EditDashboardModal from './editDashboard';
import { get, post } from '../../services/api'
import { useParams } from 'react-router-dom';

function Report() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [overallScore, setOverallScore] = React.useState(0);
    const [overallDescription, setOverallDescription] = React.useState("");
    const [taskSummary, setTaskSummary] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDataLoading, setIsDataLoading] = React.useState(true);
    const [maturityleveldata, setMaturityLevelData] = React.useState([])
    const { reportId = 1 } = useParams();
    
    const statusColors = {
        "Good": "#12B76A",
        "Average": "#FF692E",
        "Excellent": "#2970FF"
    };

    const fetchData = async () => {
        setIsDataLoading(true);
        try {
          const response = await get(`report/overall/${reportId}`);
          console.log(response.data.data)
          setOverallScore(response.data.data.overall.score);
          setOverallDescription(response.data.data.overall.description);
          setTaskSummary(response.data.data.taskSummary);
          setMaturityLevelData(response.data.data.maturityLevels)
        } catch (error) {
          console.error('Error fetching data', error);
        } finally {
          setIsDataLoading(false);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const downloadReport = async(reportId) => {
        setIsLoading(true);
        try {
            const response = await post(`report/downloadReport/${reportId}`, {}, { responseType: 'blob' });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_${reportId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading report:', error);
        } finally {
            setIsLoading(false)
        }

    }

    if (isDataLoading) { // Conditional rendering for the spinner
        return (
          <ChakraProvider>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Spinner size="lg" thickness='3px' color='#00359E' />
            </Box>
          </ChakraProvider>
        );
    }


  return (
    <>
        <ChakraProvider>
            <Box px={{base:7, md:10}} py={10} w='100%' h='fit-content' >
                <Box display='flex' flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" alignItems={{ base:'flex-start' ,md:'center'}}>
                    <Heading fontSize='24px' fontWeight='700' color='Black' mb={1} >Report</Heading>
                    <Box mt={{ base: 4, md: 0 }} w={{ base:"100%", md:"auto"}} display='flex' justifyContent='space-between' alignItems='center'>
                        <Button mr={3} variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' onClick={onOpen}>Edit Dashboard</Button>
                        <Button  bg="#2970FF" color='white' variant='solid' _hover={{ bg: '#7044c4' }} onClick={() => downloadReport(reportId)}>
                            {isLoading ? <Spinner size="sm" mr="2" /> : null}   Download Report
                        </Button>
                    </Box>
                </Box>
                <EditDashboardModal isOpen={isOpen} onClose={onClose} reportId={reportId} refreshData={fetchData}/>
                <br/>
                <Box>
                    <Text fontSize="18px" fontWeight='500' color="black">Overall scores</Text>
                    <Box mt={2} p={4}  display='flex' justifyContent='center' alignItems='center' bg='#F5F8FF' borderRadius='15px'>
                        <Box background='radial-gradient(circle, #2970FF 0%, #4C87FF 33%, #7BA7FF 66%, #D1E0FF 100%)' borderRadius='62px' width='140px' height={{base:'100px', md:'120px', lg:'100px', xl:'120px' }} display='flex' justifyContent='center' alignItems='center'>
                            <Text color='white' fontSize="32px" fontWeight='500'>{overallScore}</Text>
                        </Box>
                        <Box px={{base:'15px', md:'20px'}} width={'fit-content'} height={'fit-content'} textAlign={'justify'}>
                            <Text color='black' fontSize="14px" fontWeight='500' >
                                {overallDescription}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <br/>
                <br/>
                <Box>
                    <Text fontSize="18px" fontWeight='500' color="black">Task Score Summary</Text>
                    <Box mt={2} pt={2}>
                        <Text color='black' fontWeight='500' fontSize="14px" textAlign={'justify'}>
                            {taskSummary}                        
                        </Text>
                    </Box>
                </Box>
                <br/>
                <br/>
                <Box display="flex" flexDirection={{ base: "column", md: "row" , lg:"column", xl:"row"}} justifyContent="center" alignItems="center" width="100%">
                    <Box flex="1" pr={{ md: 2 }} mb={{ base: 4, md: 0, lg:4, xl:0 }} width={{ base: "100%", md: "50%",lg:"100%", xl:"50%" }}>
                        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl" overflow="hidden">
                        <Table variant="simple" size='small' layout='fixed'>
                            <Thead>
                            <Tr>
                                <Th py={4} verticalAlign="middle" textAlign="center" backgroundColor='#F5F8FF' borderRight="1px solid " borderColor="#D1E0FF" width="70%" whiteSpace="normal" color='black' fontSize='16px' fontWeight='700'>Assessment Track</Th>
                                <Th py={4} verticalAlign="middle" textAlign="center" backgroundColor='#F5F8FF' borderRight="1px solid " borderColor="#D1E0FF" width="30%" whiteSpace="normal" color='black' fontSize='16px' fontWeight='700'>Your Score</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            <Tr>
                                <Td py={4} px={4} verticalAlign="middle" textAlign="center" borderRight="1px solid " borderColor="#D1E0FF" whiteSpace="normal" color='#6c737f'>Environmental, Social and Governance (General)</Td>
                                <Td py={4} px={4} verticalAlign="middle" textAlign="center" borderRight="1px solid " borderColor="#D1E0FF" whiteSpace="normal" color='#6c737f'>{overallScore}</Td>
                            </Tr>
                            </Tbody>
                        </Table>
                        </TableContainer>
                    </Box>
                    <Box flex="1" pl={{ md: 2 }} width={{base: "100%", md: "50%",lg:"100%", xl:"50%" }}>
                        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl" overflow="hidden">
                        <Table variant="simple" size='small' layout='fixed'>
                            <Thead>
                            <Tr>
                                <Th py={4} px={4} verticalAlign="middle" textAlign="center" backgroundColor='#F5F8FF' border="1px solid" borderColor="#D1E0FF" width="33.33%" whiteSpace="normal" color='black' fontSize='16px' fontWeight='700'>Industry Average</Th>
                                <Th py={4} px={4} verticalAlign="middle" textAlign="center" backgroundColor='#F5F8FF' border="1px solid" borderColor="#D1E0FF" width="33.33%" whiteSpace="normal" color='black' fontSize='16px' fontWeight='700'>Industry Leaders</Th>
                                <Th py={4} px={4} verticalAlign="middle" textAlign="center" backgroundColor='#F5F8FF' border="1px solid" borderColor="#D1E0FF" width="33.33%" whiteSpace="normal" color='black' fontSize='16px' fontWeight='700'>Industry Laggards</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            <Tr>
                                <Td py={4} px={4} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" whiteSpace="normal" color='#6c737f'>-</Td>
                                <Td py={4} px={4} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" whiteSpace="normal" color='#6c737f'>-</Td>
                                <Td py={4} px={4} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" whiteSpace="normal" color='#6c737f'>-</Td>
                            </Tr>
                            </Tbody>
                        </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <br/>
                <br/>
                <Box>
                    <Text fontSize="18px" fontWeight='500' color="black">Techindicator Scorecard</Text>
                    <Box mt={2}display="flex" justifyContent="center" alignItems="center" width="100%" pt={2}>
                        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl" overflow="hidden">
                            <Table variant="simple" size='small' layout='fixed'>
                                <Thead>
                                    <Tr>
                                        <Th p={5} verticalAlign="middle" backgroundColor='#F5F8FF' borderRight="1px solid" borderColor="#D1E0FF" width="50%" whiteSpace="normal" color='black' fontSize='16px' fontWeight='700' textDecoration='none'>Techindicator</Th>
                                        <Th p={5} verticalAlign="middle" textAlign="center" backgroundColor='#F5F8FF' borderRight="1px solid" borderColor="#D1E0FF" whiteSpace="normal" width="50%" color='black' fontSize='16px' fontWeight='700' textDecoration='none'>Maturity Level</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {maturityleveldata.map((item, index) => (
                                    <Tr key={index}>
                                        <Td p={5} verticalAlign="middle" borderRight="1px solid" borderColor="#D1E0FF" color='black' fontWeight='500' fontSize='14px' whiteSpace="normal">{item.techIndicator}</Td>
                                        <Td p={5} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" whiteSpace="normal" fontWeight='500' fontSize='14px' color={statusColors[item.maturityLevel]} >{item.maturityLevel}</Td>
                                    </Tr>
                                ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    </>
  );
}

export default Report;
