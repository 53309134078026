// components/upload/LinkAdder.jsx
import React, { useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  Icon,
} from '@chakra-ui/react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as circleCloseIcon } from '../../assets/icons/circleCloseIcon.svg';

const LinkAdder = ({ links, setLinks }) => {
  const [link, setLink] = useState('');
  const [errors, setErrors] = useState('');

  const validateLink = (value) => {
    let error = '';
    if (value && value.trim() !== '') {
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\\/[^\\s]*)?$'
      );
      if (!urlPattern.test(value)) {
        error = 'Invalid Link';
      }
    }
    setErrors(error);
    return error;
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setLink(newLink);
    validateLink(newLink);
  };

  const addLink = () => {
    if (!errors && link.trim() !== '') {
      setLinks((prevLinks) => [...prevLinks, link.trim()]);
      setLink('');
      setErrors('');
    }
  };

  const removeLink = (linkToRemove) => {
    setLinks((prevLinks) => prevLinks.filter((l) => l !== linkToRemove));
  };

  return (
    <Box w="100%">
      <Box w={{ base: '100%', md: '60%', lg: '50%', xl: '40%' }}>
        <Text fontWeight="500" fontSize="18px" mb="2">
          Add Link
        </Text>
        <Box display="flex" alignItems="center">
          <InputGroup size="md">
            <Input
              type="text"
              placeholder="Type your link to be added here"
              value={link}
              variant="outline"
              borderColor={errors ? '#F04438' : '#F3F4F6'}
              focusBorderColor={errors ? '#F04438' : '#2970FF'}
              bg="#FFFFFF"
              onChange={handleLinkChange}
              sx={{
                '::placeholder': {
                  color: '#9DA4AE',
                },
              }}
            />
            <InputRightElement width="2.5rem">
              <IconButton
                isDisabled={errors || !link.trim()}
                size="sm"
                p={0}
                variant="ghost"
                _hover={{ bg: 'white' }}
                onClick={addLink}
              >
                <AddCircleOutlineOutlinedIcon
                  sx={{
                    fontSize: 25,
                    color: errors ? '#F04438' : '#9da4ae',
                    '&:hover': { color: errors ? '#F04438' : '#2970FF' },
                  }}
                />
              </IconButton>
            </InputRightElement>
          </InputGroup>
          <InfoOutlinedIcon sx={{ ml: 2, fontSize: 20, color: '#D1E0FF' }} />
        </Box>
        {errors && (
          <Text color="red.500" fontSize="sm">
            {errors}
          </Text>
        )}
      </Box>
      {links.length > 0 && (
        <Box
          p={3}
          display="flex"
          flexWrap="wrap"
          overflow="hidden"
          justifyContent="flex-start"
          alignItems="center"
          gap={2}
        >
          {links.map((item, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              py="3px"
              px="10px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              border="1px solid #D1E0FF"
              borderRadius="5px"
              backgroundColor="#D1E0FF"
            >
              <Text fontSize="12px" color="#004EEB" fontWeight="400" pr="2">
                {item}
              </Text>
              <Icon
                as={circleCloseIcon}
                w="16px"
                h="16px"
                cursor="pointer"
                onClick={() => removeLink(item)}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default LinkAdder;
