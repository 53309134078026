import '../../App.css';
import React from "react";
import { Text, Box, Button, Textarea, Modal, Switch, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast, Icon } from '@chakra-ui/react';
import { patch, get } from '../../services/api';

const ModalEditAssetion = ({ isOpen, onClose, industryTypeId, sectionId, assertionId, refreshSections }) => {
    const toast = useToast();
    const [assertionName, setAssertionName] = React.useState("");
    const [expectedAnswer, setExpectedAnswer] = React.useState("");
    const [require, setRequire] = React.useState(false);
    const [initialAssertionName, setInitialAssertionName] = React.useState("");
    const [initialExpectedAnswer, setInitialExpectedAnswer] = React.useState("");
    const [initialRequire, setInitialRequire] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const textareaARef = React.useRef();
    const textAreaRef = React.useRef();

    React.useEffect(() => {
        if (isOpen) {
            setErrors({});
            setAssertionName("");
            setExpectedAnswer("");
            const fetchAssertion = async () => {
                try {
                    const response = await get(`/reportmanagement/industry/${industryTypeId}/section/${sectionId}/assertion/${assertionId}`);
                    const assertionData = response.data.data;
                    setAssertionName(assertionData.assertionQuestion);
                    setExpectedAnswer(assertionData.assertionAnswers[0]);
                    setRequire(assertionData.require);
                    setInitialAssertionName(assertionData.assertionQuestion);
                    setInitialExpectedAnswer(assertionData.assertionAnswers[0]);
                    setInitialRequire(assertionData.require)
                } catch (error) {
                    console.error('Error fetching assertion:', error);
                }
            };
            fetchAssertion();
        }
    }, [isOpen, industryTypeId, sectionId, assertionId]);

    React.useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [expectedAnswer]);

    React.useEffect(() => {
        if (textareaARef.current) {
            textareaARef.current.style.height = 'auto';
            textareaARef.current.style.height = `${textareaARef.current.scrollHeight}px`;
        }
    }, [assertionName]);

    const validate = (field, value) => {
        let error = '';

        switch (field) {
            case 'assertionName':
                if (!value) {
                    error = 'Assertion name is required';
                }
                break;
            case 'expectedAnswer':
                if (!value) {
                    error = 'Expected answer is required';
                }
                break;
            default:
                break;
        }

        return error;
    };

    const handleExpectedAnswerChange = (value) => {
        setExpectedAnswer(value);
        const error = validate('expectedAnswer', value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            expectedAnswer: error
        }));
    };

    const handleAssertionNameChange = (value) => {
        setAssertionName(value);
        const error = validate('assertionName', value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            assertionName: error
        }));
    };

    const handleRequireChange = () => {
        setRequire(prevRequire => !prevRequire);
    };

    const handleSubmit = async () => {
        const validationErrors = {};

        validationErrors.assertionName = validate('assertionName', assertionName);
        validationErrors.expectedAnswer = validate('expectedAnswer', expectedAnswer);

        if (Object.values(validationErrors).some(error => error)) {
            setErrors(validationErrors);
        } else {
            const data = {
                industryTypeId,
                sectionId,
                assertions: [
                    {
                        assertionId,
                        assertionQuestion: assertionName,
                        assertionAnswers: [expectedAnswer],
                        require,
                    },
                ],
            };
            console.log(data);
            try {
                const response = await patch(`reportmanagement/industry/assertion/${assertionId}`, data);

                toast({
                    title: response.data.message,
                    description: 'Assertion has been updated successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });

                setAssertionName('');
                setExpectedAnswer('');
                setRequire(false);
                refreshSections();
                onClose();
            } catch (error) {
                console.error('Error submitting form:', error);
                toast({
                    title: 'Error',
                    description: error.response?.data?.message || 'An error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
        }
    };

    const isFormValid = () => {
        const isAssertionNameValid = assertionName.trim() !== '';
        const isExpectedAnswerValid = expectedAnswer.trim() !== '';
        const hasAssertionNameChanged = assertionName !== initialAssertionName;
        const hasExpectedAnswerChanged = expectedAnswer !== initialExpectedAnswer;
        const hasRequireChanged = require !== initialRequire;

        return isAssertionNameValid && isExpectedAnswerValid && (hasAssertionNameChanged || hasExpectedAnswerChanged || hasRequireChanged );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Assertion Name
                        </Text>
                        <Textarea
                            ref={textareaARef}
                            px={3}
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.assertionName ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            borderRadius='10px'
                            placeholder='Name of an Assertion'
                            size='md' resize={'none'} fontSize="14px"
                            value={assertionName}
                            lineHeight='21px'
                            minHeight='fit-content'
                            textAlign="justify"
                            onChange={(e) => handleAssertionNameChange(e.target.value)}
                            borderColor={errors.assertionName ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.assertionName && <Text color="red.500" fontSize="sm">{errors.assertionName}</Text>}
                    </Box>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Expected Answer
                        </Text>
                        <Textarea
                            ref={textAreaRef}
                            px={3}
                            border='1px solid #F3F4F6'
                            bg='#F5F8FF'
                            borderRadius='10px'
                            focusBorderColor={errors.expectedAnswer ? 'red.500' : '#2970FF'}
                            placeholder='Expected Answer'
                            size='md' resize={'none'} fontSize="14px"
                            value={expectedAnswer}
                            lineHeight='21px'
                            minHeight='fit-content' textAlign="justify"
                            onChange={(e) => handleExpectedAnswerChange(e.target.value)}
                            borderColor={errors.expectedAnswer ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.expectedAnswer && <Text color="red.500" fontSize="sm">{errors.expectedAnswer}</Text>}
                    </Box>
                    <Box  display="flex" alignItems="center">
                        <Text fontWeight="medium" fontSize='14px'>Require:</Text>
                        <Switch size='sm' colorScheme='blue' isChecked={require} onChange={handleRequireChange} ml={2} />
                    </Box>
                </ModalBody>
                <br />
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalEditAssetion;

// import '../../App.css';
// import React from "react";
// import { Text, Box, Button, Textarea, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast, Icon } from '@chakra-ui/react';
// import { patch, get } from '../../hooks/api';
// import { PlusSquareIcon, SmallCloseIcon } from '@chakra-ui/icons';

// const ModalEditAssetion = ({ isOpen, onClose, industryTypeId, sectionId, assertionId }) => {
//     const toast = useToast();
//     const [assertionName, setAssertionName] = React.useState("");
//     const [expectedAnswers, setExpectedAnswers] = React.useState([]);
//     const [initialAssertionName, setInitialAssertionName] = React.useState("");
//     const [initialExpectedAnswers, setInitialExpectedAnswers] = React.useState([]);
//     const [errors, setErrors] = React.useState({});
//     const textAreaRefs = React.useRef([]);
//     const textareaARef = React.useRef()

//     React.useEffect(() => {
//         if (isOpen) {
//             setErrors({});
//             setAssertionName("");
//             setExpectedAnswers([]);
//             const fetchAssertion = async () => {
//                 try {
//                     const response = await get(`/reportmanagement/industry/${industryTypeId}/section/${sectionId}/assertion/${assertionId}`);
//                     setAssertionName(response.data.data.assertionQuestion);
//                     setExpectedAnswers(response.data.data.assertionAnswers);
//                     setInitialAssertionName(response.data.data.assertionQuestion);
//                     setInitialExpectedAnswers(response.data.data.assertionAnswers);
//                 } catch (error) {
//                     console.error('Error fetching industry types:', error);
//                 }
//             };
//             fetchAssertion();
//         }
//     }, [isOpen, industryTypeId, sectionId, assertionId]);

//     React.useEffect(() => {
//         expectedAnswers.forEach((_, index) => {
//             if (textAreaRefs.current[index]) {
//                 textAreaRefs.current[index].style.height = 'auto';
//                 textAreaRefs.current[index].style.height = `${textAreaRefs.current[index].scrollHeight}px`;
//             }
//         });
        
//     }, [expectedAnswers]);

//     React.useEffect(() => {
//         if (textareaARef.current) {
//             textareaARef.current.style.height = 'auto';
//             textareaARef.current.style.height = `${textareaARef.current.scrollHeight}px`;
//         }
//     }, [assertionName]);

//     const validate = (field, value, index = null) => {
//         let error = '';

//         switch (field) {
//             case 'assertionName':
//                 if (!value) {
//                     error = 'Assertion name is required';
//                 }
//                 break;
//             case 'expectedAnswer':
//                 if (index !== null && !value) {
//                     error = 'Expected answer is required';
//                 }
//                 break;
//             case 'expectedAnswers':
//                 if (value.length === 0) {
//                     error = 'At least one expected answer is required';
//                 }
//                 break;
//             default:
//                 break;
//         }

//         return error;
//     };

//     const handleInputChange = (index, value) => {
//         const newAnswers = [...expectedAnswers];
//         newAnswers[index] = value;
//         setExpectedAnswers(newAnswers);

//         const error = validate('expectedAnswer', value, index);
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [`expectedAnswer${index}`]: error
//         }));
//     };

//     const handleAssertionNameChange = (value) => {
//         setAssertionName(value);
//         const error = validate('assertionName', value);
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             assertionName: error
//         }));
//     };

//     const handleAddAnswer = () => {
//         setExpectedAnswers([...expectedAnswers, ""]);
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             expectedAnswers: null
//         }));
//     };

//     const handleRemoveAnswer = (index) => {
//         const newAnswers = expectedAnswers.filter((_, i) => i !== index);
//         setExpectedAnswers(newAnswers);
//     };

//     const handleSubmit = async () => {
//         const validationErrors = {};

//         validationErrors.assertionName = validate('assertionName', assertionName);
//         expectedAnswers.forEach((answer, index) => {
//             validationErrors[`expectedAnswer${index}`] = validate('expectedAnswer', answer, index);
//         });
//         validationErrors.expectedAnswers = validate('expectedAnswers', expectedAnswers);

//         if (Object.values(validationErrors).some(error => error)) {
//             setErrors(validationErrors);
//         } else {
//             const data = {
//                 industryTypeId: industryTypeId,
//                 sectionId: sectionId,
//                 assertions: [
//                     {
//                         assertionId: assertionId,
//                         assertionQuestion: assertionName,
//                         assertionAnswers: expectedAnswers,
//                     },
//                 ],
//             };
//             console.log(data);
//             try {
//                 const response = await patch(`reportmanagement/industry/assertion/${assertionId}`, data);

//                 toast({
//                     title: response.data.message,
//                     description: 'Assertion has been updated successfully',
//                     status: 'success',
//                     duration: 5000,
//                     isClosable: true,
//                     position: 'top-right',
//                 });

//                 setAssertionName('');
//                 setExpectedAnswers([]);
//                 onClose();
//             } catch (error) {
//                 console.error('Error submitting form:', error);
//                 toast({
//                     title: 'Error',
//                     description: error.response?.data?.message || 'An error occurred',
//                     status: 'error',
//                     duration: 5000,
//                     isClosable: true,
//                     position: 'top-right',
//                 });
//             }
//         }
//     };

//     const isFormValid = () => {
//         const isAssertionNameValid = !!assertionName;

//         const areAllAnswersValid = expectedAnswers.every(answer => !!answer);

//         const hasAtLeastOneAnswer = expectedAnswers.length > 0;

//         const hasAssertionNameChanged = assertionName !== initialAssertionName;
//         const haveExpectedAnswersChanged = JSON.stringify(expectedAnswers) !== JSON.stringify(initialExpectedAnswers);

//         return isAssertionNameValid && areAllAnswersValid && hasAtLeastOneAnswer && (hasAssertionNameChanged || haveExpectedAnswersChanged);
//     };

//     return (
//         <Modal isOpen={isOpen} onClose={onClose}>
//             <ModalOverlay />
//             <ModalContent my={4} p={4}>
//                 <ModalBody>
//                     <Box mb="5">
//                         <Text fontWeight="medium" fontSize='14px' mb="2">
//                             Assertion Name
//                         </Text>
//                         <Textarea
//                             ref={textareaARef}
//                             px={3}
//                             border='1px solid #F3F4F6'
//                             focusBorderColor={errors.assertionName ? 'red.500' : '#2970FF'}
//                             bg="#F5F8FF"
//                             borderRadius='10px'
//                             placeholder='Name of an Assertion'
//                             size='md' resize={'none'} fontSize="14px"
//                             value={assertionName}
//                             lineHeight='21px'
//                             minHeight='fit-content'
//                             textAlign="justify"
//                             onChange={(e) => handleAssertionNameChange(e.target.value)}
//                             borderColor={errors.assertionName ? 'red.500' : '#D1E0FF'}
//                             onInput={(e) => {
//                                 e.target.style.height = 'auto';
//                                 e.target.style.height = `${e.target.scrollHeight}px`;
//                             }}
//                             sx={{
//                                 '::placeholder': {
//                                   color: '#9DA4AE',
//                                 },
//                               }}
//                             css={{
//                                 '::-webkit-scrollbar': { display: 'none' },
//                                 '-ms-overflow-style': 'none',
//                                 'scrollbar-width': 'none'
//                             }}
//                         />
//                         {errors.assertionName && <Text color="red.500" fontSize="sm">{errors.assertionName}</Text>}
//                     </Box>
//                     {expectedAnswers.map((answer, index) => (
//                         <Box mb="5" key={index} display="flex" alignItems="center">
//                             <Box flex="1">
//                                 <Box display="flex" justifyContent="space-between" alignItems="center">
//                                     <Text fontWeight="medium" fontSize='14px' mb="2">
//                                         Expected Answer {index + 1}
//                                     </Text>
//                                     <Icon
//                                         as={SmallCloseIcon}
//                                         boxSize={4}
//                                         color="#2970FF"
//                                         cursor="pointer"
//                                         onClick={() => handleRemoveAnswer(index)}
//                                         ml={2}
//                                     />
//                                 </Box>
//                                 <Textarea
//                                     ref={el => textAreaRefs.current[index] = el}
//                                     px={3}
//                                     border='1px solid #F3F4F6'
//                                     bg='#F5F8FF'
//                                     borderRadius='10px'
//                                     focusBorderColor={errors[`expectedAnswer${index}`] ? 'red.500' : '#2970FF'}
//                                     placeholder='Expected Answer'
//                                     size='md' resize={'none'} fontSize="14px"
//                                     value={answer}
//                                     lineHeight='21px'
//                                     minHeight='fit-content' textAlign="justify"
//                                     onChange={(e) => handleInputChange(index, e.target.value)}
//                                     borderColor={errors[`expectedAnswer${index}`] ? 'red.500' : '#D1E0FF'}
//                                     onInput={(e) => {
//                                         e.target.style.height = 'auto';
//                                         e.target.style.height = `${e.target.scrollHeight}px`;
//                                     }}
//                                     sx={{
//                                         '::placeholder': {
//                                           color: '#9DA4AE',
//                                         },
//                                       }}
//                                     css={{
//                                         '::-webkit-scrollbar': { display: 'none' },
//                                         '-ms-overflow-style': 'none',
//                                         'scrollbar-width': 'none'
//                                     }}
//                                 />
//                                 {errors[`expectedAnswer${index}`] && <Text color="red.500" fontSize="sm">{errors[`expectedAnswer${index}`]}</Text>}
//                             </Box>
//                         </Box>
//                     ))}
//                     {errors.expectedAnswers &&<Box display="flex" justifyContent="center" mb={5}> <Text color="red.500" fontSize="sm">{errors.expectedAnswers}</Text> </Box>}
//                     <Box display='flex' justifyContent='center' alignItems='center'>
//                         <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={handleAddAnswer}>
//                             <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
//                             <Text fontWeight="medium" fontSize='14px' color='#2970FF' >Add Answer</Text>
//                         </Box>
//                     </Box>
//                 </ModalBody>
//                 <br />
//                 <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
//                     <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
//                     <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
//                         Save
//                     </Button>
//                 </ModalFooter>
//             </ModalContent>
//         </Modal>
//     );
// };

// export default ModalEditAssetion;