// components/upload/SuggestedCategory.jsx
import React from 'react';
import {
  Box,
  Text,
  Wrap,
  WrapItem,
  VStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as fileIcon } from '../../assets/icons/fileIcon.svg';

const SuggestedCategory = ({ selectedOptionSuggest, setSelectedOptionSuggest }) => {
  const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
  const menuButtonRef = React.useRef();

  React.useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);

  const sections = {
    'ESG Strategy & Execution': [
      'Employee Handbook',
      'ESG Strategy'
    ],
    'Business Ethics': [
      'Code of Ethics',
    ],
    'Human Capital': [
      'HR Policy - equal opportunity',
      'Non Discrimination',
      'Intellectual Property',
      'Whistleblower or Issue Escalation',
      'Non Discrimination',
      'Compensation',
      'Harassment',
      'Time',
      'Attendance',
      'Recruitment'
    ],
    'Supply Chain': [
      'Supply Chain Policy (import, export, trade agreements, cross border, etc.)',
    ],
    'Natural Resource Management': [
      'Natural Resouce Policy',
    ],
    'Product Oversight': [
      'Product Design and Governance',
    ],
    'Data Security & Privacy': [
      'Cyber Security',
      'Data Management',
      'Bring Your Own Device',
      'Information Security'
    ],
    'Marketing Integrity': [
      'Ethical Marketing',
    ],
    'Climate Risk': [
      'Climate Change Risk Management Policy',
    ],
    'Systemic Risk': [
      'Workplace health and safety',
      'Business Continuity',
      'Disaster recovery'
    ],
  };

  const options = Object.keys(sections);

  return (
    <>
      <Box w={{ base: '100%', md: '60%', lg: '50%', xl: '40%' }}>
        <Text fontWeight="500" fontSize="18px" mb="2">
          Suggested Category
        </Text>
        <Box display="flex" alignItems="center">
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  ref={menuButtonRef}
                  w="100%"
                  bg="white"
                  border="2px solid #F3F4F6"
                  fontWeight="400"
                  _hover={{ bg: 'white', borderColor: '#2970FF' }}
                  _active={{ bg: 'white', borderColor: '#D1E0FF' }}
                  _focus={{ bg: 'white' }}
                  as={Button}
                  textAlign="left"
                  rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                  {selectedOptionSuggest}
                </MenuButton>
                <MenuList
                  width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'}
                  p={2}
                  borderColor="#EFF4FF"
                  borderRadius="12px"
                  maxHeight="400px"
                  overflowY="auto"
                  css={{
                    '::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                  }}
                >
                  {options.map((option, index) => (
                    <React.Fragment key={index}>
                      <MenuItem
                        onClick={() => setSelectedOptionSuggest(option)}
                        borderRadius="5px"
                        bg={selectedOptionSuggest === option ? '#EFF4FF' : 'white'}
                        _hover={{ bg: '#EFF4FF' }}
                        color="#00359E"
                        textAlign="left"
                      >
                        <Box
                          w="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {option}
                          {selectedOptionSuggest === option && (
                            <Box as="span" color="#00359E" ml={2}>
                              <CheckIcon boxSize={3} />
                            </Box>
                          )}
                        </Box>
                      </MenuItem>
                      {index < options.length - 1 && (
                        <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
                      )}
                    </React.Fragment>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
          <InfoOutlinedIcon sx={{ ml: 2, fontSize: 20, color: '#D1E0FF' }} />
        </Box>
      </Box>
      <br />
      <Box w="100%" bg="#ECFDF3" p={6}>
        <Text fontSize="16px" fontWeight="600" color="black" mb={6}>
          Suggested Documents
        </Text>
        <Wrap spacingX={20} spacingY={6} align="center" justify="start">
          {sections[selectedOptionSuggest]?.map((feature, index) => (
            <WrapItem key={index}>
              <VStack>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Icon as={fileIcon} w="13px" h="17px" />
                  <Text px={2} fontSize="14px" fontWeight="500">
                    {feature}
                  </Text>
                </Box>
              </VStack>
            </WrapItem>
          ))}
        </Wrap>
      </Box>
    </>
  );
};

export default SuggestedCategory;
