import React from 'react';
import { Box, Text, Input } from '@chakra-ui/react';

const InputField = ({ label, error, isRequired, ...props }) => (
  <Box mb="5">
    <Text fontWeight="medium" fontSize="14px" mb="2">
      {label}
      {isRequired && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
    </Text>
    <Input
      {...props}
      variant="outline"
      border="1px solid #F3F4F6"
      focusBorderColor={error ? 'red.500' : '#D1E0FF'}
      bg="white"
      borderColor={error ? 'red.500' : '#F3F4F6'}
      size="md"
      sx={{
        '::placeholder': {
          color: '#9DA4AE',
        },
        whiteSpace: 'nowrap',
        overflow: 'auto',
      }}
    />
    {error && (
      <Text color="red.500" fontSize="sm">
        {error}
      </Text>
    )}
  </Box>
);

export default InputField;