export const validateField = (field, value, file) => {
    let error = '';
  
    switch (field) {
      case 'subject':
        if (!value) {
          error = 'Subject is required';
        }
        break;
      case 'description':
        if (!value) {
          error = 'Description is required';
        }
        break;
      case 'file':
        if (file.length === 0) {
          error = 'At least one image file is required';
        }
        break;
      default:
        break;
    }
  
    return error;
};

export default validateField;