import React, { useState, useEffect } from 'react';
import { Box, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Icon
} from '@chakra-ui/react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="10px">
        <ModalBody px={5} pt={5}>
          <Box display="flex" alignItems="center">
            <Box mr={3} sx={{ backgroundColor: '#FEE4E2', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '45px', height: '45px' }}>
              <Icon as={WarningAmberRoundedIcon} w="30px" height="30px" sx={{ color: '#F04438' }} />
            </Box>
            <Box>
              <Text fontWeight="bold" fontSize="18px">Delete</Text>
              <Text fontSize='14px' fontWeight='500' color='#6C737F'>Are you sure you want to delete {message}?</Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter >
          <Button variant='ghost' bg='white' color='black' onClick={onClose}>Cancel</Button>
          <Button colorScheme="red" onClick={onConfirm} ml={3}>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
);

export default DeleteConfirmationModal