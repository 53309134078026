import React from 'react';
import ReactDOM from 'react-dom/client';
// import { useLocation,
//   useNavigationType,
//   createRoutesFromChildren,
//   matchRoutes } from "react-router-dom";
import './index.css';
import App from './App';
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://2b18beee095745918348e8396dc49a33@sentry.squareboat.com/128",
//   integrations: [
//     Sentry.reactRouterV6BrowserTracingIntegration({
//         useEffect: React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//     }),
//     Sentry.replayIntegration()
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);