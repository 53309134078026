import React, { useState, useEffect } from 'react';
import { ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, TableContainer, Tooltip
} from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { CheckIcon } from '@chakra-ui/icons';
import { get, post } from '../../services/api';
import PopoverMenu from '../popover/assessmentPopover';

const muiTheme = createTheme();

function AdminAssessmentTableComponent({
  searchTerm,
  sortOption = { sortBy: 'createdAt', sortOrder: '-1', status: [] },
  currentPage,
  setCurrentPage,
  updateNavigateState,
  isAdmin,
  userId
}) {
  const pageSize = 10;
  const [assessmentData, setAssessmentData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState('-1');
  const [selectedSortBy, setSelectedSortBy] = useState('createdAt');


  // Fetch function
  const fetchUserDetails = async (page, searchTerm, sortBy, order, status = []) => {
    console.log( sortBy, order)
    try {
      const response = await get(`assessment/${userId}/list`, {
        params: {
          page: page,
          limit: pageSize,
          query: searchTerm,
          sortBy: sortBy,
          sortOrder: order,
          sortStatus: status,
          isAdmin: isAdmin,
        }
      });
      console.log(response.data.data)
      setAssessmentData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching assessment list:', error);
    }
  };

  // Handlers
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    fetchUserDetails(newPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status);
    updateNavigateState(newPage, searchTerm, sortOption, sortOption.status);
  };

  const handleSortChange = (sortBy, order) => {
    setSelectedOrder(order);
    setSelectedSortBy(sortBy);
    setCurrentPage(1);
    updateNavigateState(1, searchTerm, { ...sortOption, sortBy, sortOrder: order }, sortOption.status);
    fetchUserDetails(1, searchTerm, sortBy, order, sortOption.status);
  };

  // Effects
  // useEffect(() => {
  //   fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status);
  // }, [isAdmin, userId, selectedSortBy, selectedOrder, searchTerm, sortOption.status, currentPage]);

  useEffect(() => {
    if (isAdmin !== undefined && userId) {
      fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, userId, selectedSortBy, selectedOrder, searchTerm, sortOption.status, currentPage]);
  

  useEffect(() => {
    const interval = setInterval(() => {
      if (assessmentData.some(assessment => assessment.status === 'In Process')) {
        fetchUserDetails(currentPage, searchTerm, selectedSortBy, selectedOrder, sortOption.status);
      }
    }, 300000);

    return () => clearInterval(interval);
  }, [assessmentData]);

  // Status colors
  const statusColors = {
    "Completed": "#12B76A",
    "In Process": "#FF692E",
  };

  return (
    <ChakraProvider>
      <Box width="100%" py={4}>
        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th py={4} borderColor="#D1E0FF" colSpan={6} color='black' fontSize={'18px'} backgroundColor={'#F5F8FF'} textTransform="none">
                  All Assessments
                </Th>
                <Th py={3} borderColor="#D1E0FF" backgroundColor={'#F5F8FF'} display={'flex'} justifyContent={'flex-end'}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<FilterListOutlinedIcon />}
                      bg="#D1E0FF"
                      color="#00395E"
                      _hover={{ bg: "#c5d7fc" }}
                      _active={{ bg: "#c5d7fc" }}
                      _focus={{ bg: "#c5d7fc" }}
                      textAlign="right"
                      h="38px"
                    >
                      Sort
                    </MenuButton>
                    <MenuList width="fit-content" minWidth="0" p={4} borderColor="#EFF4FF" borderRadius="12px">
                      <MenuItem
                        borderRadius="5px"
                        bg={selectedOrder === '-1' ? "#EFF4FF" : "white"}
                        _hover={{ bg: "#EFF4FF" }}
                        color="#00359E"
                        onClick={() => handleSortChange('createdAt', '-1')}
                      >
                        <Box w="100%" display="flex" justifyContent="space-between" alignItems="center">
                          Latest First
                          {selectedOrder === '-1' && (
                            <Box as="span" color="#00359E" ml={2}>
                              <CheckIcon boxSize={3} />
                            </Box>
                          )}
                        </Box>
                      </MenuItem>
                      <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
                      <MenuItem
                        borderRadius="5px"
                        bg={selectedOrder === 'asc' ? "#EFF4FF" : "white"}
                        _hover={{ bg: "#EFF4FF" }}
                        color="#00359E"
                        onClick={() => handleSortChange('assessmentName', 'asc')}
                      >
                        Alphabetical Asc. (A to Z)
                        {selectedOrder === 'asc' && (
                          <Box as="span" color="#00359E" ml={4}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                      <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
                      <MenuItem
                        borderRadius="5px"
                        bg={selectedOrder === 'desc' ? "#EFF4FF" : "white"}
                        _hover={{ bg: "#EFF4FF" }}
                        color="#00359E"
                        onClick={() => handleSortChange('assessmentName', 'desc')}
                      >
                        Alphabetical Des. (Z to A)
                        {selectedOrder === 'desc' && (
                          <Box as="span" color="#00359E" ml={4}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Th>
              </Tr>
            </Thead>
            <Thead>
              <Tr>
                <Th py={4} fontSize={'14px'} borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  Assessment Name
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  User Name
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  Company Name
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  ESG Score
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  Date
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  Status
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} textTransform="none">
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {assessmentData.length > 0 ? (
               assessmentData.map(item => (
                <Tr key={item.assessmentId}>
                  <Td borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>
                    {item.assessmentName}
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>
                    {item.userName}
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>
                    {item.companyName}
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>
                    {item.esgScore === null ? '-' : item.esgScore}
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='#6C737F' fontSize='14px' fontWeight='500'>
                    {new Date(item.createdAt).toLocaleDateString('en-GB')}
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color={statusColors[item.status] || "#2970FF"} fontSize='14px' fontWeight='500'>
                  <Tooltip label={item.status !== 'Completed' ? 'You will receive an email once the report is generated.' : 'Report is Generated'} placement='top' hasArrow arrowSize={9}>
                    {item.status}
                  </Tooltip>
                  </Td>
                  <Td verticalAlign="middle" textAlign="center" borderColor="#D1E0FF">
                    {item.status !== 'Completed' ? '-' : <PopoverMenu reportId={item.reportId} />}
                  </Td>
                </Tr>
              ))
              ) : (
                <Tr>
                  <Td colSpan={7} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' whiteSpace="normal">
                    {searchTerm
                      ? 'No assessment found'
                      : 'No assessment started. Please create your first assessment'}
                  </Td>
                </Tr>
              )}
              <Tr>
                <Th colSpan={7} verticalAlign="middle" textAlign={'center'} backgroundColor={'#F5F8FF'}>
                  <ThemeProvider theme={muiTheme}>
                    <Box w='100%' display="flex" justifyContent="center" alignItems="center">
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                          '& .MuiPaginationItem-root': {
                            borderColor: '#D1E0FF',
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#2970FF',
                              borderColor: '#2970FF',
                            },
                            '&.Mui-selected': {
                              backgroundColor: 'white',
                              color: '#2970FF',
                              borderColor: '#2970FF',
                            }
                          },
                          '& .MuiPaginationItem-previousNext': {
                            backgroundColor: '#F5F8FF',
                            borderColor: '#D1E0FF',
                            color: '#9E9E9E',
                            '&:hover': {
                              backgroundColor: '#F5F8FF',
                              borderColor: '#2970FF',
                              color: '#2970FF',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#F5F8FF',
                              color: '#2970FF',
                              borderColor: '#2970FF',
                            },
                            '&.Mui-disabled': {
                              backgroundColor: '#6C737F',
                              color: '#EEEEEE',
                              borderColor: '#6C737F',
                            }
                          }
                        }}
                      />
                    </Box>
                  </ThemeProvider>
                </Th>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </ChakraProvider>
  );
}

export default AdminAssessmentTableComponent;
