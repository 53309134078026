import '../../App.css';
import React from "react";
import { Text, Box, Button, Input, Modal, Switch, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast, Textarea, Icon, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Flex } from '@chakra-ui/react';
import { patch, get } from '../../services/api';
import { PlusSquareIcon, SmallCloseIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';

const ModalAddSection = ({ isOpen, onClose, refreshSections, industryId }) => {
    const toast = useToast();
    const [sections, setSections] = React.useState([]);
    const [sectionname, setSectionname] = React.useState("");
    const [assertions, setAssertions] = React.useState([{ assertionName: "", expectedAnswer: "", require:false }]);
    const [errors, setErrors] = React.useState({});
    const [showNewSectionInput, setShowNewSectionInput] = React.useState(false);
    const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
    const menuButtonRef = React.useRef();
    const textAreaRefs = React.useRef([]);

    React.useEffect(() => {
        if (menuButtonRef.current) {
            setMenuButtonWidth(menuButtonRef.current.clientWidth);
        }
    }, [menuButtonRef.current]);

    React.useEffect(() => {
        if (isOpen) {
            fetchSections();
            setErrors({});
            setSectionname("");
            setAssertions([{ assertionName: "", expectedAnswer: "", require:false }]);
        }
    }, [isOpen]);

    const fetchSections = async () => {
        try {
            const response = await get('/reportmanagement/section');
            const section = response.data.data.map(item => item.section);
            setSections(section);
        } catch (error) {
            console.error('Error fetching section:', error);
        }
    };

    const validate = (field, value) => {
        let error = '';

        switch (field) {
            case 'sectionName':
                if (!value) {
                    error = 'Section name is required';
                }
                break;
            case 'assertionName':
                if (!value) {
                    error = 'Assertion name is required';
                }
                break;
            case 'expectedAnswer':
                if (!value) {
                    error = 'Expected Answer is required';
                }
                break;
            default:
                break;
        }

        return error;
    };

    React.useEffect(() => {
        assertions.forEach((assertion, index) => {
            if (textAreaRefs.current[index]) {
                textAreaRefs.current[index].style.height = 'auto';
                textAreaRefs.current[index].style.height = `${textAreaRefs.current[index].scrollHeight}px`;
            }
        });
    }, [assertions]);

    const handleInputChange = (assertionIndex, value) => {
        const newAssertions = [...assertions];
        newAssertions[assertionIndex].expectedAnswer = value;
        setAssertions(newAssertions);
        setErrors({});
    };

    const handleAssertionNameChange = (index, value) => {
        const newAssertions = [...assertions];
        newAssertions[index].assertionName = value;
        setAssertions(newAssertions);
        setErrors({});
    };

    const handleRequireChange = (index) => {
        const newAssertions = [...assertions];
        newAssertions[index].require = !newAssertions[index].require;
        setAssertions(newAssertions);
    };

    const handleSectionNameChange = (value) => {
        setSectionname(value);
        setErrors({});
    };

    const handleAddAssertion = () => {
        setAssertions([...assertions, { assertionName: "", expectedAnswer: "", require: false }]);
    };

    const handleRemoveAssertion = (index) => {
        const newAssertions = [...assertions];
        newAssertions.splice(index, 1);
        setAssertions(newAssertions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

        if (!sectionname) {
            newErrors.sectionName = 'Section name is required';
        }

        assertions.forEach((assertion, assertionIndex) => {
            if (!assertion.assertionName) {
                newErrors[`assertionName_${assertionIndex}`] = 'Assertion name is required';
            }
            if (!assertion.expectedAnswer) {
                newErrors[`expectedAnswer_${assertionIndex}`] = 'Expected Answer is required';
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const data = {
            industryTypeId: industryId,
            section: {
                name: sectionname,
                assertions: assertions.map(assertion => ({
                    assertionQuestion: assertion.assertionName,
                    assertionAnswers: [assertion.expectedAnswer], 
                    require:assertion.require
                })),
            },
        };
        try {
            const response = await patch(`reportmanagement/industry/section`, data);

            toast({
                title: response.data.message,
                description: 'New section has been created successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });

            setSectionname('');
            setAssertions([{ assertionName: "", expectedAnswer: "", require: false }]);
            refreshSections();
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            toast({
                title: 'Error',
                description: error.response?.data?.message || 'An error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
        }
    };

    const isFormValid = () => {
        if (!sectionname.trim()) {
            return false;
        }

        if (Object.values(errors).length > 0) {
            return false;
        }

        return assertions.every(assertion =>
            assertion.assertionName.trim() &&
            assertion.expectedAnswer.trim()
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalBody>
                    {!showNewSectionInput ? (
                        <>
                            <Text fontWeight="medium" fontSize='14px' mb="2">
                                Choose the Section
                            </Text>
                            <Menu>
                                {({ isOpen }) => (
                                    <>
                                        <MenuButton
                                            ref={menuButtonRef}
                                            w='100%'
                                            value={sectionname}
                                            bg="#F5F8FF"
                                            border='1px solid #F3F4F6'
                                            borderColor={errors.sectionName ? 'red.500' : '#F3F4F6'}
                                            fontWeight='400'
                                            _hover={{ bg: 'white', borderColor: errors.sectionName ? 'red.500' : '#D1E0FF' }}
                                            _active={{ bg: 'white', borderColor: errors.sectionName ? 'red.500' : '#D1E0FF' }}
                                            _focus={{ bg: 'white', borderColor: errors.sectionName ? 'red.500' : '#D1E0FF' }}
                                            as={Button}
                                            textAlign='left'
                                            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                            color={!sectionname ? "#9DA4AE" : "inherit"}
                                        >
                                            {sectionname || 'Select a section'}
                                        </MenuButton>
                                        <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={1.5} borderColor='#EFF4FF' borderRadius='12px' maxHeight="200px" overflowY="auto">
                                            {sections.map((option, index) => (
                                                <React.Fragment key={index}>
                                                    <MenuItem
                                                        onClick={() => { setSectionname(option); }}
                                                        borderRadius="5px"
                                                        bg={sectionname === option ? "#EFF4FF" : "white"}
                                                        _hover={{ bg: "#EFF4FF" }}
                                                        color="#00395E"
                                                        textAlign="left"
                                                    >
                                                        <Flex justify="space-between" align="center" w="100%">
                                                            <Box>{option}</Box>
                                                            {sectionname === option && (
                                                                <Box as="span" color="#00359E" ml={2}>
                                                                    <CheckIcon boxSize={3} />
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </MenuItem>
                                                    {index < sections.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                                                </React.Fragment>
                                            ))}
                                        </MenuList>
                                    </>
                                )}
                            </Menu>
                            {errors.sectionName && <Text color="red.500" fontSize="sm">{errors.sectionName}</Text>}

                            <Box display='flex' justifyContent='center' alignItems='center' mt="4">
                                <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={() => { setShowNewSectionInput(true); setSectionname(''); }}>
                                    <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
                                    <Text fontWeight="medium" fontSize='14px' color='#2970FF'>Add New Section</Text>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box mb="5">
                            <Text fontWeight="medium" fontSize='14px' mb="2">Name of the New Section</Text>
                            <Input
                                variant='outline'
                                border='1px solid #F3F4F6'
                                focusBorderColor={errors.sectionName ? 'red.500' : '#2970FF'}
                                bg="#F5F8FF"
                                placeholder='Name of a Section'
                                size='md'
                                value={sectionname}
                                onChange={(e) => handleSectionNameChange(e.target.value)}
                                borderColor={errors.sectionName ? 'red.500' : '#D1E0FF'}
                                sx={{
                                    '::placeholder': {
                                        color: '#9DA4AE',
                                    },
                                    whiteSpace: 'nowrap',
                                    overflow: 'auto',
                                }}
                            />
                            {errors.sectionName && <Text color="red.500" fontSize="sm">{errors.sectionName}</Text>}
                        </Box>
                    )}
                    {assertions.map((assertion, assertionIndex) => (
                        <Box mb="5" key={assertionIndex}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Text fontWeight="medium" fontSize='14px' mb="2">
                                    Assertion {assertionIndex + 1}
                                </Text>
                                {assertionIndex > 0 && (
                                    <Icon
                                        as={SmallCloseIcon}
                                        boxSize={4}
                                        color='red.500'
                                        cursor='pointer'
                                        onClick={() => handleRemoveAssertion(assertionIndex)}
                                    />
                                )}
                            </Box>
                            <Box py={2} px={3} border='1px solid #D1E0FF' borderRadius='10px' height='fit-content'>
                                <Box mb="5">
                                    <Text fontWeight="medium" fontSize='14px' mb="2">
                                        Assertion Name
                                    </Text>
                                    <Textarea
                                        px={3}
                                        border='1px solid #F3F4F6'
                                        focusBorderColor={errors[`assertionName_${assertionIndex}`] ? 'red.500' : '#2970FF'}
                                        bg="#F5F8FF"
                                        borderRadius='10px'
                                        placeholder='Name of an Assertion'
                                        size='md' resize={'none'} fontSize="14px"
                                        value={assertion.assertionName}
                                        lineHeight='21px'
                                        minHeight='fit-content' textAlign="justify"
                                        onChange={(e) => handleAssertionNameChange(assertionIndex, e.target.value)}
                                        borderColor={errors[`assertionName_${assertionIndex}`] ? 'red.500' : '#D1E0FF'}
                                        onInput={(e) => {
                                            e.target.style.height = 'auto';
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                        sx={{
                                            '::placeholder': {
                                                color: '#9DA4AE',
                                            },
                                        }}
                                        css={{
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-ms-overflow-style': 'none',
                                            'scrollbar-width': 'none'
                                        }}
                                    />
                                    {errors[`assertionName_${assertionIndex}`] && <Text color="red.500" fontSize="sm">{errors[`assertionName_${assertionIndex}`]}</Text>}
                                </Box>
                                <Box mb="5">
                                    <Text fontWeight="medium" fontSize='14px' mb="2">
                                        Expected Answer
                                    </Text>
                                    <Textarea
                                        ref={el => textAreaRefs.current[assertionIndex] = el}
                                        px={3}
                                        border='1px solid #F3F4F6'
                                        bg='#F5F8FF'
                                        borderRadius='10px'
                                        focusBorderColor={errors[`expectedAnswer_${assertionIndex}`] ? 'red.500' : '#2970FF'}
                                        placeholder='Expected Answer'
                                        size='sm' fontSize="14px"
                                        resize={'none'}
                                        height='100px'
                                        minHeight='fit-content' textAlign="justify"
                                        value={assertion.expectedAnswer}
                                        onChange={(e) => handleInputChange(assertionIndex, e.target.value)}
                                        borderColor={errors[`expectedAnswer_${assertionIndex}`] ? 'red.500' : '#D1E0FF'}
                                        onInput={(e) => {
                                            e.target.style.height = 'auto';
                                            e.target.style.height = `${e.target.scrollHeight}px`;
                                        }}
                                        sx={{
                                            '::placeholder': {
                                                color: '#9DA4AE',
                                            },
                                        }}
                                        css={{
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-ms-overflow-style': 'none',
                                            'scrollbar-width': 'none'
                                        }}
                                    />
                                    {errors[`expectedAnswer_${assertionIndex}`] && <Text color="red.500" fontSize="sm">{errors[`expectedAnswer_${assertionIndex}`]}</Text>}
                                </Box>
                                <Box display="flex" alignItems="center" mt="2">
                                    <Text fontWeight="medium" fontSize='14px'>Required:</Text>
                                    <Switch size='sm' colorScheme='blue' isChecked={assertion?.require} onChange={() => handleRequireChange(assertionIndex)} ml={2} />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    <Box display='flex' justifyContent='center' alignItems='center' mb="5">
                        <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={handleAddAssertion}>
                            <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
                            <Text fontWeight="medium" fontSize='14px' color='#2970FF'>Add Assertion</Text>
                        </Box>
                    </Box>
                </ModalBody>
                <br />
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={() => { onClose(); setShowNewSectionInput(false); }}>Cancel</Button>
                    <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalAddSection;


// import '../../App.css';
// import React from "react";
// import { Text, Box, Button, Input, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast, Textarea, Icon, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Flex } from '@chakra-ui/react';
// import { patch, get } from '../../hooks/api';
// import { PlusSquareIcon, SmallCloseIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';

// const ModalAddSection = ({ isOpen, onClose, refreshSections, industryId }) => {
//     const toast = useToast();
//     const [sections, setSections] = React.useState([])
//     const [sectionname, setSectionname] = React.useState("");
//     const [assertions, setAssertions] = React.useState([{ assertionName: "", expectedAnswers: [""] }]);
//     const [errors, setErrors] = React.useState({});
//     const [showNewSectionInput, setShowNewSectionInput] = React.useState(false);
//     const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
//     const menuButtonRef = React.useRef();
//     const textAreaRefs = React.useRef([[]]);
  
//     React.useEffect(() => {
//       if (menuButtonRef.current) {
//         setMenuButtonWidth(menuButtonRef.current.clientWidth);
//       }
//     }, [menuButtonRef.current]);

//     React.useEffect(() => {
//         if (isOpen) {
//             fetchSections();
//             setErrors({});
//             setSectionname("");
//             setAssertions([{ assertionName: "", expectedAnswers: [""] }]);
//         }
//     }, [isOpen]);

//     // React.useEffect(() => {
//     const fetchSections = async () => {
//         try {
//              const response = await get('/reportmanagement/section');
//             const section = response.data.data.map(item => (item.section));
//             setSections(section);
//         } catch (error) {
//             console.error('Error fetching section:', error);
//         }
//     };
        
//     //     fetchSections();
//     // }, []);

//     const validate = (field, value) => {
//         let error = '';

//         switch (field) {
//             case 'sectionName':
//                 if (!value) {
//                     error = 'Section name is required';
//                 }
//                 break;
//             case 'assertionName':
//                 if (!value) {
//                     error = 'Assertion name is required';
//                 }
//                 break;
//             case 'expectedAnswer':
//                 if (!value.length) {
//                     error = 'Expected Answer is required';
//                 }
//                 break;
//             default:
//                 break;
//         }

//         return error;
//     };

//     React.useEffect(() => {
//         assertions.forEach((assertion, index) => {
//             assertion.expectedAnswers.forEach((_, ansIndex) => {
//                 if (textAreaRefs.current[index][ansIndex]) {
//                     textAreaRefs.current[index][ansIndex].style.height = 'auto';
//                     textAreaRefs.current[index][ansIndex].style.height = `${textAreaRefs.current[index][ansIndex].scrollHeight}px`;
//                 }
//             });
//         });
//     }, [assertions]);

//     const handleInputChange = (assertionIndex, answerIndex, value) => {
//         const newAssertions = [...assertions];
//         newAssertions[assertionIndex].expectedAnswers[answerIndex] = value;
//         setAssertions(newAssertions);
//         setErrors({});
//     };

//     const handleAssertionNameChange = (index, value) => {
//         const newAssertions = [...assertions];
//         newAssertions[index].assertionName = value;
//         setAssertions(newAssertions);
//         setErrors({});
//     };

//     const handleSectionNameChange = (value) => {
//         setSectionname(value);
//         setErrors({});
//     };

//     const handleAddAnswer = (index) => {
//         const newAssertions = [...assertions];
//         newAssertions[index].expectedAnswers.push("");
//         setAssertions(newAssertions);
//     };

//     const handleRemoveAnswer = (assertionIndex, answerIndex) => {
//         const newAssertions = [...assertions];
//         newAssertions[assertionIndex].expectedAnswers.splice(answerIndex, 1);
//         setAssertions(newAssertions);
//     };

//     const handleAddAssertion = () => {
//         setAssertions([...assertions, { assertionName: "", expectedAnswers: [""] }]);
//         textAreaRefs.current.push([]);
//     };

//     const handleRemoveAssertion = (index) => {
//         const newAssertions = [...assertions];
//         newAssertions.splice(index, 1);
//         setAssertions(newAssertions);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const newErrors = {};

//         if (!sectionname) {
//             newErrors.sectionName = 'Section name is required';
//         }

//         assertions.forEach((assertion, assertionIndex) => {
//             if (!assertion.assertionName) {
//                 newErrors[`assertionName_${assertionIndex}`] = 'Assertion name is required';
//             }
//             assertion.expectedAnswers.forEach((answer, answerIndex) => {
//                 if (!answer) {
//                     newErrors[`expectedAnswer_${assertionIndex}_${answerIndex}`] = 'Expected Answer is required';
//                 }
//             });
//         });

//         if (Object.keys(newErrors).length > 0) {
//             setErrors(newErrors);
//             return;
//         }

//         const data = {
//             industryTypeId: industryId,
//             section: {
//                 name: sectionname,
//                 assertions: assertions.map(assertion => ({
//                     assertionQuestion: assertion.assertionName,
//                     assertionAnswers: assertion.expectedAnswers,
//                 })),
//             },
//         };
//         try {
//             const response = await patch(`reportmanagement/industry/section`, data);

//             toast({
//                 title: response.data.message,
//                 description: 'New section has been created successfully',
//                 status: 'success',
//                 duration: 5000,
//                 isClosable: true,
//                 position: 'top-right',
//             });

//             setSectionname('');
//             setAssertions([{ assertionName: "", expectedAnswers: [""] }]);
//             refreshSections();
//             onClose();
//         } catch (error) {
//             console.error('Error submitting form:', error);
//             if (error.response.status === 400) {
//                 // Show error toast
//                 toast({
//                     title: 'Error',
//                     description: error.response.data.message,
//                     status: 'error',
//                     duration: 5000,
//                     isClosable: true,
//                     position: 'top-right',
//                 });
//             } else if (error.response.status === 404) {
//                 toast({
//                     title: 'Error',
//                     description: error.response.data.message,
//                     status: 'error',
//                     duration: 5000,
//                     isClosable: true,
//                     position: 'top-right',
//                 });
//             } else {
//                 toast({
//                     title: 'Error',
//                     description: error.response.data.message,
//                     status: 'error',
//                     duration: 5000,
//                     isClosable: true,
//                     position: 'top-right',
//                 });
//             }
//         }
//     };

//     const isFormValid = () => {
//         if (!sectionname.trim()) {
//             return false;
//         }
    
//         if (Object.values(errors).length > 0) {
//             return false;
//         }
    
//         return assertions.every(assertion => 
//             assertion.assertionName.trim() && 
//             assertion.expectedAnswers.every(answer => answer.trim())
//         );
//     };

//     return (
//         <Modal isOpen={isOpen} onClose={onClose}>
//             <ModalOverlay />
//             <ModalContent my={4} p={4}>
//                 <ModalBody>
//                     {!showNewSectionInput ? (
//                         <>
//                             <Text fontWeight="medium" fontSize='14px' mb="2">
//                                 Choose the Section
//                             </Text>
//                             <Menu>
//                                 {({ isOpen }) => (
//                                     <>
//                                         <MenuButton
//                                             ref={menuButtonRef}
//                                             w='100%'
//                                             value={sectionname}
//                                             bg="#F5F8FF"
//                                             border='1px solid #F3F4F6'
//                                             borderColor={errors.sectionName ? 'red.500' : '#F3F4F6'}
//                                             fontWeight='400'
//                                             _hover={{ bg: 'white', borderColor: errors.sectionName ? 'red.500' : '#D1E0FF' }}
//                                             _active={{ bg: 'white', borderColor: errors.sectionName ? 'red.500' : '#D1E0FF' }}
//                                             _focus={{ bg: 'white', borderColor: errors.sectionName ? 'red.500' : '#D1E0FF' }}
//                                             as={Button}
//                                             textAlign='left'
//                                             rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
//                                             color={!sectionname ? "#9DA4AE" : "inherit"}
//                                         >
//                                             {sectionname || 'Select a section'}
//                                         </MenuButton>
//                                         <MenuList width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'} p={1.5} borderColor='#EFF4FF' borderRadius='12px' maxHeight="200px" overflowY="auto">
//                                             {sections.map((option, index) => (
//                                             <React.Fragment key={index}>
//                                                 <MenuItem
//                                                     onClick={() => { setSectionname(option); }}
//                                                     borderRadius="5px"
//                                                     bg={sectionname === option ? "#EFF4FF" : "white"}
//                                                     _hover={{ bg: "#EFF4FF" }}
//                                                     color="#00395E"
//                                                     textAlign="left"
//                                                 >
//                                                     <Flex justify="space-between" align="center" w="100%">
//                                                         <Box>{option}</Box>
//                                                         {sectionname === option && (
//                                                         <Box as="span" color="#00359E" ml={2}>
//                                                             <CheckIcon boxSize={3} />
//                                                         </Box>
//                                                         )}
//                                                     </Flex>
//                                                 </MenuItem>
//                                                 {index < sections.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
//                                             </React.Fragment>
//                                             ))}
//                                         </MenuList>
//                                     </>
//                                 )}
//                             </Menu>
//                             {errors.sectionName && <Text color="red.500" fontSize="sm">{errors.sectionName}</Text>}

//                             <Box display='flex' justifyContent='center' alignItems='center' mt="4">
//                                 <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={() => {setShowNewSectionInput(true); setSectionname('')}}>
//                                     <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
//                                     <Text fontWeight="medium" fontSize='14px' color='#2970FF'>Add New Section</Text>
//                                 </Box>
//                             </Box>
//                         </>
//                     ) : (
//                         <Box mb="5">
//                             <Text fontWeight="medium" fontSize='14px' mb="2">Name of the New Section</Text>
//                             <Input
//                                 variant='outline'
//                                 border='1px solid #F3F4F6'
//                                 focusBorderColor={errors.sectionName ? 'red.500' : '#2970FF'}
//                                 bg="#F5F8FF"
//                                 placeholder='Name of a Section'
//                                 size='md'
//                                 value={sectionname}
//                                 onChange={(e) => handleSectionNameChange(e.target.value)}
//                                 borderColor={errors.sectionName ? 'red.500' : '#D1E0FF'}
//                                 sx={{
//                                     '::placeholder': {
//                                         color: '#9DA4AE',
//                                     },
//                                     whiteSpace: 'nowrap',
//                                     overflow: 'auto',
//                                 }}
//                             />
//                             {errors.sectionName && <Text color="red.500" fontSize="sm">{errors.sectionName}</Text>}
//                         </Box>
//                     )}
//                     {assertions.map((assertion, assertionIndex) => (
//                         <Box mb="5" key={assertionIndex}>
//                             <Box display="flex" justifyContent="space-between" alignItems="center">
//                                 <Text fontWeight="medium" fontSize='14px' mb="2">
//                                     Assertion {assertionIndex + 1}
//                                 </Text>
//                                 {assertionIndex > 0 && (
//                                     <Icon
//                                         as={SmallCloseIcon}
//                                         boxSize={4}
//                                         color='red.500'
//                                         cursor='pointer'
//                                         onClick={() => handleRemoveAssertion(assertionIndex)}
//                                     />
//                                 )}
//                             </Box>
//                             <Box py={2} px={3} border='1px solid #D1E0FF' borderRadius='10px' height='fit-content'>
//                                 <Box mb="5">
//                                     <Text fontWeight="medium" fontSize='14px' mb="2">
//                                         Assertion Name
//                                     </Text>
//                                     <Textarea
//                                         px={3}
//                                         border='1px solid #F3F4F6'
//                                         focusBorderColor={errors[`assertionName_${assertionIndex}`] ? 'red.500' : '#2970FF'}
//                                         bg="#F5F8FF"
//                                         borderRadius='10px'
//                                         placeholder='Name of an Assertion'
//                                         size='md' resize={'none'} fontSize="14px"
//                                         value={assertion.assertionName}
//                                         lineHeight='21px'
//                                         minHeight='fit-content' textAlign="justify"
//                                         onChange={(e) => handleAssertionNameChange(assertionIndex, e.target.value)}
//                                         borderColor={errors[`assertionName_${assertionIndex}`] ? 'red.500' : '#D1E0FF'}
//                                         onInput={(e) => {
//                                             e.target.style.height = 'auto';
//                                             e.target.style.height = `${e.target.scrollHeight}px`;
//                                         }}
//                                         sx={{
//                                             '::placeholder': {
//                                               color: '#9DA4AE',
//                                             },
//                                           }}
//                                         css={{
//                                             '::-webkit-scrollbar': { display: 'none' },
//                                             '-ms-overflow-style': 'none',
//                                             'scrollbar-width': 'none'
//                                         }}
//                                     />
//                                     {errors[`assertionName_${assertionIndex}`] && <Text color="red.500" fontSize="sm">{errors[`assertionName_${assertionIndex}`]}</Text>}
//                                 </Box>
//                                 {assertion.expectedAnswers.map((answer, answerIndex) => (
//                                     <Box mb="5" key={answerIndex}>
//                                         <Box display="flex" justifyContent="space-between" alignItems="center">
//                                             <Text fontWeight="medium" fontSize='14px' mb="2">
//                                                 Expected Answer {answerIndex + 1}
//                                             </Text>
//                                             {answerIndex > 0 && (
//                                                 <Icon
//                                                     as={SmallCloseIcon}
//                                                     boxSize={4}
//                                                     color='red.500'
//                                                     cursor='pointer'
//                                                     onClick={() => handleRemoveAnswer(assertionIndex, answerIndex)}
//                                                 />
//                                             )}
//                                         </Box>
//                                         <Textarea
//                                             ref={el => textAreaRefs.current[assertionIndex][answerIndex] = el}
//                                             px={3}
//                                             border='1px solid #F3F4F6'
//                                             bg='#F5F8FF'
//                                             borderRadius='10px'
//                                             focusBorderColor={errors[`expectedAnswer_${assertionIndex}_${answerIndex}`] ? 'red.500' : '#2970FF'}
//                                             placeholder='Expected Answer'
//                                             size='sm' fontSize="14px"
//                                             resize={'none'}
//                                             height='100px'
//                                             minHeight='fit-content' textAlign="justify"
//                                             onChange={(e) => handleInputChange(assertionIndex, answerIndex, e.target.value)}
//                                             borderColor={errors[`expectedAnswer_${assertionIndex}_${answerIndex}`] ? 'red.500' : '#D1E0FF'}
//                                             onInput={(e) => {
//                                                 e.target.style.height = 'auto';
//                                                 e.target.style.height = `${e.target.scrollHeight}px`;
//                                             }}
//                                             sx={{
//                                                 '::placeholder': {
//                                                   color: '#9DA4AE',
//                                                 },
//                                               }}
//                                             css={{
//                                                 '::-webkit-scrollbar': { display: 'none' },
//                                                 '-ms-overflow-style': 'none',
//                                                 'scrollbar-width': 'none'
//                                             }}
//                                         />
//                                         {errors[`expectedAnswer_${assertionIndex}_${answerIndex}`] && <Text color="red.500" fontSize="sm">{errors[`expectedAnswer_${assertionIndex}_${answerIndex}`]}</Text>}
//                                     </Box>
//                                 ))}
//                                 <Box display='flex' justifyContent='center' alignItems='center' mb="5">
//                                     <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={() => handleAddAnswer(assertionIndex)}>
//                                         <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
//                                         <Text fontWeight="medium" fontSize='14px' color='#2970FF'>Add Answer</Text>
//                                     </Box>
//                                 </Box>
//                             </Box>
//                         </Box>
//                     ))}
//                     <Box display='flex' justifyContent='center' alignItems='center' mb="5">
//                         <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={handleAddAssertion}>
//                             <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
//                             <Text fontWeight="medium" fontSize='14px' color='#2970FF'>Add Assertion</Text>
//                         </Box>
//                     </Box>
//                 </ModalBody>
//                 <br />
//                 <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
//                     <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={() => { onClose(); setShowNewSectionInput(false)}}>Cancel</Button>
//                     <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
//                         Submit
//                     </Button>
//                 </ModalFooter>
//             </ModalContent>
//         </Modal>
//     );
// };

// export default ModalAddSection;