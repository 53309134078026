import React from 'react';
import { useToast } from '@chakra-ui/react';
import { post } from '../services/api';
import { validateField } from '../utils/feedbackValidators';
import useAdminCheck from './useAdminCheck';

const useFeedbackForm = () => {
  const toast = useToast();
  const [subject, setSubject] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [file, setFile] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const { userId } = useAdminCheck();
  const fileInputRef = React.useRef(null);

  const handleFileChange = (event) => {
    setFile(Array.from(event.target.files));
    setErrors((prev) => ({ ...prev, file: '' }));
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const removeFile = (filename) => {
    setFile(file.filter((file) => file.name !== filename));
  };

  const validate = (field, value) => {
    const error = validateField(field, value, file);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleSubmit = async () => {
    const fields = ['subject', 'description', 'file'];

    const validationErrors = {};

    fields.forEach((field) => {
      const value = field === 'file' ? file : eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = new FormData();
    data.append('subject', subject);
    data.append('description', description);
    file.forEach((file) => data.append('uploadDocument', file));

    try {
      const response = await post(`feedbacks/${userId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast({
        title: 'Feedback created successfully',
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setSubject('');
      setDescription('');
      setFile([]);
      fileInputRef.current.value = null;
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const isFormInvalid = () => {
    return (
      !subject ||
      !description ||
      file.length === 0 ||
      Object.values(errors).some((error) => error)
    );
  };

  return {
    subject,
    setSubject,
    description,
    setDescription,
    file,
    setFile,
    errors,
    setErrors,
    fileInputRef,
    handleFileChange,
    handleFileButtonClick,
    removeFile,
    validate,
    handleBlur,
    handleSubmit,
    isFormInvalid,
  };
};

export default useFeedbackForm;
