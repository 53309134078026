import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';

const SelectField = ({ label, options, selectedOption, setSelectedOption, error, isRequired, onBlur }) => {
  const [menuButtonWidth, setMenuButtonWidth] = useState(null);
  const menuButtonRef = useRef();
  const optionSelectedRef = useRef(false);

  useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);

  const handleMenuClose = () => {
    if (!optionSelectedRef.current) {
      onBlur();
    }
    optionSelectedRef.current = false;
  };

  const handleOptionSelect = (option) => {
    optionSelectedRef.current = true;
    setSelectedOption(option);
  };

  return (
    <Box mb="5">
      <Text fontWeight="medium" fontSize="14px" mb="2">
        {label}
        {isRequired && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
      </Text>
      <Menu onClose={handleMenuClose}>
        {({ isOpen }) => (
          <>
            <MenuButton
              ref={menuButtonRef}
              w="100%"
              bg="white"
              border="1px solid #F3F4F6"
              borderColor={error ? 'red.500' : '#F3F4F6'}
              fontWeight="400"
              _hover={{ bg: 'white', borderColor: error ? 'red.500' : '#D1E0FF' }}
              _active={{ bg: 'white', borderColor: error ? 'red.500' : '#D1E0FF' }}
              _focus={{ bg: 'white', borderColor: error ? 'red.500' : '#D1E0FF' }}
              as={Button}
              textAlign="left"
              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              color={!selectedOption ? '#9DA4AE' : 'inherit'}
              overflow="hidden"
            >
              <Box
                maxW="95%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {selectedOption || 'Select the option'}
              </Box>
            </MenuButton>
            <MenuList
              width={menuButtonWidth ? `${menuButtonWidth}px` : 'auto'}
              p={2}
              zIndex={2}
              borderColor="#EFF4FF"
              borderRadius="12px"
              maxHeight="300px"
              overflowY="auto"
              css={{
                '::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none'
              }}

            >
              {options.map((option, index) => (
                <React.Fragment key={index}>
                  <MenuItem
                    onClick={() => handleOptionSelect(option)}
                    borderRadius="5px"
                    bg={selectedOption === option ? '#EFF4FF' : 'white'}
                    _hover={{ bg: '#EFF4FF' }}
                    color="#00359E"
                    textAlign="left"
                  >
                    <Flex justify="space-between" align="center" w="100%">
                      <Box>{option}</Box>
                      {selectedOption === option && (
                        <Box as="span" color="#00359E" ml={2}>
                          <CheckIcon boxSize={3} />
                        </Box>
                      )}
                    </Flex>
                  </MenuItem>
                  {index < options.length - 1 && <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />}
                </React.Fragment>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
      {error && (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default SelectField;
