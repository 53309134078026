import React from 'react';
import { ChakraProvider, Box, Heading, Text } from '@chakra-ui/react';
import UserDetails from '../../components/account/userDetails';
import CompanyDetails from '../../components/account/companyDetails';
import ChangePassword from '../../components/account/changePassword';
import useAdminCheck from '../../hooks/useAdminCheck';

const Account = () => {
  const { userId } = useAdminCheck();

  return (
    <ChakraProvider>
      <Box px={[4, 6, 10]} py={10} w="100%" h="fit-content" overflowY="scroll">
        <Box mb={6}>
          <Heading size="md" fontSize="24px" fontWeight="700" mb={2}>
            Account Details
          </Heading>
          <Text fontSize="18px" fontWeight="500" color="#6C737F">
            Update your account details here
          </Text>
        </Box>
        <UserDetails userId={userId} />
        <CompanyDetails userId={userId} />
        <ChangePassword userId={userId} />
      </Box>
    </ChakraProvider>
  );
};

export default Account;
