import React, { useState } from 'react';
import {
  Box,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const PasswordField = ({ label, error, isRequired, ...props }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Box mb="5">
      <Text fontWeight="medium" fontSize="14px" mb="2">
        {label}
        {isRequired && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
      </Text>
      <InputGroup size="md">
        <Input
          {...props}
          type={show ? 'text' : 'password'}
          variant="outline"
          border="1px solid #F3F4F6"
          focusBorderColor={error ? 'red.500' : '#D1E0FF'}
          bg="white"
          borderColor={error ? 'red.500' : '#F3F4F6'}
          size="md"
          sx={{
            '::placeholder': {
              color: '#9DA4AE',
            },
            whiteSpace: 'nowrap',
            overflow: 'auto',
          }}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" bg="white" _hover={{ bg: 'white' }} onClick={handleClick}>
            {show ? (
              <VisibilityOutlinedIcon sx={{ color: '#9DA4AE' }} />
            ) : (
              <VisibilityOffOutlinedIcon sx={{ color: '#9DA4AE' }} />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
      {error && (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default PasswordField;
