import React, { useState, useEffect } from 'react';
import {ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Box, TableContainer, Button, Menu, Text, Link, MenuButton, MenuList, MenuItem, 
  MenuDivider } from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PopoverMenu from '../popover/feedbackPopover';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { CheckIcon } from '@chakra-ui/icons';
import { get } from '../../services/api';

const muiTheme = createTheme();

function FeedbackTableComponent({ searchTerm }) {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [feedbackData, setFeedbackData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('latest');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [status, setStatus] = useState('');

  const statusColors = {
    "read": "#12B76A",
    "unread": "#FF692E",
  };
  
  const fetchUserDetails = async (page, searchTerm, sortBy, order, status) => {
    try {
      const response = await get(`feedbacks/feedback/list`, {
        params: {
          page: page,
          limit: pageSize,
          query: searchTerm,
          sortBy: sortBy,
          sortOrder: order,
          sortStatus: status
        }
      });
      setFeedbackData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching feedback list:', error);
    }
  };

  const refreshFeedbackData = () => {
    fetchUserDetails(currentPage, searchTerm, sortBy, sortOrder, status);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);
  
  useEffect(() => {
    fetchUserDetails(currentPage, searchTerm, sortBy, sortOrder, status);
  }, [currentPage, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
     fetchUserDetails(newPage, searchTerm, sortBy, sortOrder, status);
  };
  
  useEffect(() => {
    setCurrentPage(1);
    fetchUserDetails(1, searchTerm, sortBy, sortOrder, status);
  }, [searchTerm, sortBy, sortOrder, status]);
  
  const handleFilterChange = (filterType, sortBy, order, status) => {
    if (selectedFilter === filterType) {
      setSelectedFilter('');
      setSortBy('');
      setSortOrder('');
      setStatus('');
      fetchUserDetails(currentPage, searchTerm, '', '', '');
    } else {
      setSelectedFilter(filterType);
      setSortBy(sortBy);
      setSortOrder(order);
      setStatus(status);
      fetchUserDetails(currentPage, searchTerm, sortBy, order, status);
    }
  };
  
  return (
    <ChakraProvider>
      <Box width="100%" py={4}>
        <TableContainer width="full" border="1px solid" borderColor="#D1E0FF" borderRadius="xl">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th py={4} borderColor="#D1E0FF" colSpan={5} color='black' fontSize={'16px'} backgroundColor={'#F5F8FF'} textTransform="none">Feedback</Th>
                <Th py={3} borderColor="#D1E0FF" backgroundColor={'#F5F8FF'} display={'flex'} justifyContent={'flex-end'}>
                  <Menu>
                    <MenuButton as={Button} rightIcon={<FilterListOutlinedIcon />}
                      bg="#D1E0FF"
                      color="black"
                      _hover={{ bg: "#c5d7fc" }}
                      _active={{ bg: "#c5d7fc" }}
                      _focus={{ bg: "#c5d7fc" }}
                      textAlign="right"
                      h="38px"
                    >
                      Sort
                    </MenuButton>
                    <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                      <MenuItem
                        borderRadius='5px'
                        bg={selectedFilter === 'latest' ? "#EFF4FF" : "white"}
                        _hover={{ bg: "#EFF4FF" }}
                        color='#00395E'
                        onClick={() => handleFilterChange('latest', 'createdAt', 'desc','')}
                      >
                        Latest First
                        {selectedFilter === 'latest' && (
                          <Box as="span" color="#00359E" ml={2}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                      <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                      <MenuItem
                        borderRadius='5px'
                        bg={selectedFilter === 'unread' ? "#EFF4FF" : "white"}
                        _hover={{ bg: "#EFF4FF" }}
                        color='#00395E'
                        onClick={() => handleFilterChange('unread', 'status', 'desc', 'unread')}
                      >
                        Unread First
                        {selectedFilter === 'unread' && (
                          <Box as="span" color="#00359E" ml={2}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                      <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                      <MenuItem
                        borderRadius='5px'
                        bg={selectedFilter === 'read' ? "#EFF4FF" : "white"}
                        _hover={{ bg: "#EFF4FF" }}
                        color='#00395E'
                        onClick={() => handleFilterChange('read', 'status', 'desc', 'read')}
                      >
                        Read First
                        {selectedFilter === 'read' && (
                          <Box as="span" color="#00359E" ml={2}>
                            <CheckIcon boxSize={3} />
                          </Box>
                        )}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Th>
              </Tr>
            </Thead>
            <Thead>
              <Tr>
                <Th py={4} fontSize={'14px'} borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='15%' whiteSpace="normal" textTransform="none">User Name</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='15%' whiteSpace="normal" textTransform="none">Email Address</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='15%' whiteSpace="normal" textTransform="none">Company Name</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='50%' whiteSpace="normal" textTransform="none">
                  Feedback
                  <style jsx>{`
                    .feedback-text {
                      max-height: 3em;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      word-break: break-word;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      white-space: normal;
                    }
                  `}</style>
                </Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='10%' whiteSpace="normal" textTransform="none">Status</Th>
                <Th py={4} fontSize={'14px'} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" color='black' backgroundColor={'#F5F8FF'} width='10%' textTransform="none">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackData.length > 0 ? (
                feedbackData.map(item => (
                  <Tr key={item.feedbackId}>
                    <Td borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='15%' whiteSpace="normal">{item.userName}</Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='15%' whiteSpace="normal">
                      <a href={`mailto:${item.emailId}`} style={{ textDecoration: 'none', color: '#6C737F' }}  onMouseOver={(e) => e.currentTarget.style.color = '#00359E'} onMouseOut={(e) => e.currentTarget.style.color = '#6C737F'}>
                        <div className="email-text">{item.emailId}</div>
                      </a>
                    </Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='15%' whiteSpace="normal">{item.companyName}</Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' width='50%' whiteSpace="normal">
                      <div className="feedback-text">{item.description}</div>
                    </Td>
                    <Td verticalAlign="middle" textAlign="center" borderRight="1px solid" borderColor="#D1E0FF" color={statusColors[item.status]} width='10%'>{item.status === 'read' ? 'Read' : 'Unread'}</Td>
                    <Td verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" width='10%'><PopoverMenu feedbackId={item.feedbackId} feedbackStatus={item.status} refreshFeedbackData={refreshFeedbackData} /></Td>
                  </Tr>
                ))
                ) : (
                  <Tr>
                    <Td colSpan={6} verticalAlign="middle" textAlign="center" borderColor="#D1E0FF" fontSize='14px' fontWeight='500' color='#6C737F' whiteSpace="normal">
                    {searchTerm
                      ? 'No feedback found'
                      : 'No feedback received'}
                  </Td>
                </Tr>
                )}
                <Tr>
                  <Th colSpan={7} verticalAlign="middle" textAlign={'center'} backgroundColor={'#F5F8FF'}>
                    <ThemeProvider theme={muiTheme}>
                      <Box w='100%' display="flex" justifyContent="center" alignItems="center">
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          sx={{
                            '& .MuiPaginationItem-root': {
                              borderColor: '#D1E0FF',
                              backgroundColor: 'white',
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: '#2970FF',
                                borderColor: '#2970FF',
                              },
                              '&.Mui-selected': {
                                backgroundColor: 'white',
                                color: '#2970FF',
                                borderColor: '#2970FF',
                              }
                            },
                            '& .MuiPaginationItem-previousNext': {
                              backgroundColor: '#F5F8FF',
                              borderColor: '#D1E0FF',
                              color: '#9E9E9E',
                              '&:hover': {
                                backgroundColor: '#F5F8FF',
                                borderColor: '#2970FF',
                                color: '#2970FF',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F5F8FF',
                                color: '#2970FF',
                                borderColor: '#2970FF',
                              },
                              '&.Mui-disabled': {
                                backgroundColor: '#6C737F',
                                color: '#EEEEEE',
                                borderColor: '#6C737F',
                              }
                            }
                          }}
                        />
                      </Box>
                    </ThemeProvider>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </ChakraProvider>
    );
  }

export default FeedbackTableComponent;
