import React, { useEffect, useState } from 'react';
import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Textarea, Button, UnorderedList, ListItem, Box, useToast } from '@chakra-ui/react';
import { get, patch } from '../../services/api';

const AddRemarksModal = ({ isOpen, onClose, reportId, type, id, refreshData }) => {
    const toast = useToast();
    const [itemDetails, setItemDetails] = useState({ heading: "", answer: [] });
    const [remarks, setRemarks] = useState("");
    const [originalRemarks, setOriginalRemarks] = useState("");
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonText, setButtonText] = useState("Add Remarks");
    const [headerText, setHeaderText] = useState("Add Remarks");

    const validate = (field, value) => {
        let error = '';

        switch (field) {
            case 'remarks':
                if (remarks.length <= 0) {
                    error = 'Remark is required';
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: error,
        }));

        return error;
    };

    const handleSubmit = async () => {
        const error = validate('remarks', remarks);
        if (error) {
            return;
        }
        const sentences = remarks.split('.').map(sentence => sentence.trim()).filter(sentence => sentence);
        const data = {
            reportId,
            type,
            id,
            remark: sentences
        };
        try {
            const response = await patch(`report/assessment/${reportId}/remark`, data);

            toast({
                title: response.data.message,
                description: 'Remark is added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            setRemarks("");
            refreshData()
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            if(error.response.status === 400)
            {
                // Show error toast
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
            else if(error.response.status === 404){
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }

        }
    };

    const handleTextareaChange = (e) => {
        setRemarks(e.target.value);
        setIsButtonDisabled(e.target.value.trim() === originalRemarks.trim() || e.target.value.trim() === '');
    };

    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                try {
                    const endpoint = type === 'strength' ? `keyStrength/${id}` : `keyFinding/${id}`;
                    const response = await get(`report/assessment/${reportId}/${endpoint}`);
                    const fetchedRemark = response.data.data.remark || '';
                    const formattedOriginalRemark = Array.isArray(fetchedRemark) ? fetchedRemark.join('. ') : fetchedRemark;

                    setItemDetails(response.data.data);
                    setRemarks(formattedOriginalRemark);
                    setOriginalRemarks(formattedOriginalRemark);
                    setIsButtonDisabled(true);

                    if (formattedOriginalRemark.trim() === "") {
                        setButtonText("Add Remarks");
                        setHeaderText("Add Remarks");
                    } else {
                        setButtonText("Save");
                        setHeaderText("Edit Remarks");
                    }
                } catch (error) {
                    console.error('Error fetching data', error);
                }
            };

            fetchData();
        }
    }, [isOpen, reportId, type, id]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalHeader color='black' fontWeight='500' fontSize='18px' pb={2}>{itemDetails.heading}</ModalHeader>
                <ModalBody>
                    <UnorderedList>
                        {itemDetails.answer.map((point, index) => (
                            <ListItem key={index} fontSize="16px" fontWeight="400" color='#6C737F' lineHeight='22px' textAlign={'justify'}>{point}</ListItem>
                        ))}
                    </UnorderedList>
                    <br />
                    <Text mb={1} fontWeight="500" fontSize='16px' color="#111927">{headerText}</Text>
                    <Box>
                        <Textarea
                            px={3}
                            border='1px solid'
                            borderColor={errors.remarks ? 'red.500' : '#D1E0FF'}
                            bg='#F5F8FF'
                            borderRadius='10px'
                            focusBorderColor={errors.remarks ? 'red.500' : '#2970FF'}
                            placeholder='Enter the remark'
                            size='sm'
                            fontSize="14px"
                            resize={'none'}
                            height='180px'
                            value={remarks}
                            onChange={handleTextareaChange}
                            sx={{
                                '::placeholder': {
                                    color: '#9DA4AE',
                                },
                            }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.remarks && <Text color="red.500" fontSize="sm">{errors.remarks}</Text>}
                    </Box>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button
                        bg={isButtonDisabled ? "#F04438" : "#2970FF" }
                        color='white'
                        variant='solid'
                        width='47.5%'
                        _hover={{ bg: isButtonDisabled ? "#F04438" : "#7044c4"  }}
                        onClick={handleSubmit}
                        isDisabled={isButtonDisabled}
                    >
                        {buttonText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AddRemarksModal;

