import React from 'react';
import { Box, Grid, GridItem, Button, Text, InputGroup, Input, InputRightElement, useToast } from '@chakra-ui/react';
  import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
  import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PasswordField from '../form/passwordField';
import useFormValidation from '../../hooks/useFormValidation';
import { patch } from '../../services/api';

const ChangePassword = ({ userId }) => {
    const { values, errors, handleChange, handleBlur, isFormValid } = useFormValidation({
      password: '',
      confirmPassword: '',
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const toast = useToast();
  
    const handlePasswordChange = async () => {
      if (!isFormValid()) return;
  
      const data = {
        password: values.password,
        confirmpassword: values.confirmPassword,
      };
  
      try {
        const response = await patch(`users/${userId}/password`, data);
        // Reset password fields
        handleChange('password', '');
        handleChange('confirmPassword', '');
  
        // Show success toast
        toast({
          title: 'Password updated',
          description: response.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        console.error('Error updating password:', error);
        if (
          error.response &&
          error.response.data.message === 'Password does not match'
        ) {
          handleChange('confirmPassword', '', 'Password does not match');
        }
        // Show error toast
        toast({
          title: 'Error',
          description: error.response?.data?.message || 'An error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    };
  
    return (
      <>
        <Text fontSize="20px" fontWeight="600" color="black" mb="8px">
          Change Password
        </Text>
        <Box
          w="100%"
          bg="#F5F8FF"
          p="20px"
          h="fit-content"
          border="1px solid #D1E0FF"
          borderRadius="12px"
        >
          <Box>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
              <GridItem w="100%" h="fit-content">
                <Box>
                  <Text fontWeight="medium" fontSize="14px" mb={2}>
                    Enter your password
                    <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </Text>
                  <InputGroup size="md">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      variant="outline"
                      border="1px solid #F3F4F6"
                      focusBorderColor={errors.password ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder="Please enter your password"
                      size="md"
                      value={values.password}
                      onChange={(e) => handleChange('password', e.target.value)}
                      onBlur={() => handleBlur('password')}
                      borderColor={errors.password ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        bg="white"
                        _hover={{ bg: 'white' }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon sx={{ color: '#9DA4AE' }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ color: '#9DA4AE' }} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <Text color="red.500" fontSize="sm">
                      {errors.password}
                    </Text>
                  )}
                </Box>
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <Box>
                  <Text fontWeight="medium" fontSize="14px" mb={2}>
                    Enter your confirm password
                    <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                  </Text>
                  <InputGroup size="md">
                    <Input
                      type={showConfirmPassword ? 'text' : 'password'}
                      variant="outline"
                      border="1px solid #F3F4F6"
                      focusBorderColor={errors.confirmPassword ? 'red.500' : '#D1E0FF'}
                      bg="white"
                      placeholder="Please enter your confirm password"
                      size="md"
                      value={values.confirmPassword}
                      onChange={(e) =>
                        handleChange('confirmPassword', e.target.value, values.password)
                      }
                      onBlur={() => handleBlur('confirmPassword')}
                      borderColor={errors.confirmPassword ? 'red.500' : '#F3F4F6'}
                      sx={{
                        '::placeholder': {
                          color: '#9DA4AE',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        bg="white"
                        _hover={{ bg: 'white' }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOutlinedIcon sx={{ color: '#9DA4AE' }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ color: '#9DA4AE' }} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {errors.confirmPassword && (
                    <Text color="red.500" fontSize="sm">
                      {errors.confirmPassword}
                    </Text>
                  )}
                </Box>
              </GridItem>
            </Grid>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(6, 1fr)' }} gap={6} mt={6}>
              <GridItem w="100%" h="fit-content">
                <Button
                  bg={isFormValid() ? '#2970FF' : '#F04438'}
                  color="white"
                  variant="solid"
                  width="100%"
                  _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }}
                  onClick={handlePasswordChange}
                  isDisabled={!isFormValid()}
                >
                  Change Password
                </Button>
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };
  
  export default ChangePassword;