import React, { useState, useEffect } from 'react';
import {
  Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, useToast, useDisclosure, 
} from '@chakra-ui/react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteConfirmationModal from '../modals/deleteConfirmModal';
import { get, patch, remove } from '../../services/api';
import ViewFeedback from '../modals/feedbackTableModal';

const PopoverMenu = ({feedbackId, refreshFeedbackData , feedbackStatus }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  
  const updateFeedback = async (status) =>{
    const data = {
      status: status,
    };
    try {
      const response = await patch(`feedbacks/${feedbackId}`, data);
      // Show success toast
      toast({
        title: response.data.message,
        description: 'Feedback has been updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      refreshFeedbackData()   
    } catch (error) {
      console.error('Error submitting form:', error);
      if(error.response.status === 400) {
      // Show error toast
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      else if(error.response.status === 404) {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  }

  const deleteFeedback = async () =>{
    try {
      const response = await remove(`feedbacks/${feedbackId}`);
      // Show success toast
      toast({
        title: response.data.message,
        description: 'Feedback has been deleted successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
  
      refreshFeedbackData();
    } catch (error) {
      console.error('Error submitting form:', error);
      if(error.response.status === 400) {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else if(error.response.status === 404){
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  }
  
  const handleDeleteConfirm = () => {
    deleteFeedback();
    onDeleteClose();
  }
  
  return (
  <>
    <Menu>
      <MenuButton as={Button}
        bg="white"
        color="black"
        _hover={{ bg: "white" }}
        _active={{ bg: "white" }}
        _focus={{ bg: "white" }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
        <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={onOpen}>View Feedback
        <ViewFeedback isOpen={isOpen} onClose={onClose} feedbackId={feedbackId} refreshFeedbackData ={refreshFeedbackData}/> </MenuItem>
        <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
        { feedbackStatus !== 'read' && ( 
          <>
            <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => updateFeedback('read')}  >Mark as Read</MenuItem>
            <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
          </>
        )}
        { feedbackStatus !== 'unread' && (
          <>
            <MenuItem borderRadius='5px' bg="white" _hover={{ bg: "#EFF4FF" }} color='#00359E' onClick={() => updateFeedback('unread')}>Mark as Unread</MenuItem>
            <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
          </>
        )}
        <MenuItem borderRadius='5px'bg="white" _hover={{ bg: "#FEF3F2" }} color='#F04438' onClick={onDeleteOpen}>Delete Feedback  </MenuItem>
      </MenuList>
    </Menu>
    <DeleteConfirmationModal
      isOpen={isDeleteOpen}
      onClose={onDeleteClose}
      onConfirm={handleDeleteConfirm}
      message="feedback"
    />
  </>
  );
};

export default PopoverMenu