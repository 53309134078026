// components/upload/CompanyInfoForm.jsx
import React from 'react';
import { Box, Grid, GridItem, Text, Input, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Button, Icon } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@chakra-ui/icons';
import InputField from '../form/inputField';
import SelectField from '../form/selectField';

const CompanyInfoForm = ({
  companyName,
  setCompanyName,
  numberOfEmployees,
  setNumberOfEmployees,
  industryType,
  setIndustryType,
  industryOptions,
  errors,
  validate,
  handleBlur,
}) => {
  const [menuButtonWidth, setMenuButtonWidth] = React.useState(null);
  const menuButtonRef = React.useRef();

  React.useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(menuButtonRef.current.clientWidth);
    }
  }, [menuButtonRef.current]);

  return (
    <Box>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={{ base: 0, md: 6 }}>
        <GridItem w="100%" h="fit-content">
            <InputField
                label="Name of company"
                value={companyName}
                error={errors.companyName}
                onChange={(e) => { setCompanyName(e.target.value); validate('companyName', e.target.value); }}
                onBlur={() => handleBlur('companyName')}
                placeholder="Enter Company name"
                isRequired
            />
        </GridItem>
        <GridItem w="100%" h="fit-content">
            <SelectField
                label="Select the industry type"
                options={industryOptions}
                selectedOption={industryType}
                setSelectedOption={(option) => setIndustryType(option)}
                error={errors.industryType}
                onBlur={() => handleBlur('industryType')}
                isRequired
            />
        </GridItem>
      </Grid>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
        <GridItem w="100%" h="fit-content">
            <InputField
                label="Number of Employees in company - (Optional)"
                value={numberOfEmployees}
                error={errors.numberOfEmployees}
                onChange={(e) => { setNumberOfEmployees(e.target.value); validate('numberOfEmployees', e.target.value); }}
                onBlur={() => handleBlur('numberOfEmployees')}
                placeholder="Enter size of the company"
            />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default CompanyInfoForm;