import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import MainLayout from './layout/MainLayout';
import './App.css';

const App = () => (
  <Router>
    <AuthProvider>
      <MainLayout />
    </AuthProvider>
  </Router>
);

export default App;