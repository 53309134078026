import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { setupAxios } from '../services/api'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');

      if (token && refreshToken) {
        try {
          const decodedUser = jwtDecode(refreshToken);
          setIsAuthenticated(true);
          setUser(decodedUser);
        } catch (error) {
          console.error('Invalid token:', error);
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  // useEffect(() => {
  //   const publicRoutes = ['/login', '/forget-password', '/otp', '/reset-password'];
  //   if (!loading && !isAuthenticated && !publicRoutes.includes(location.pathname)) {
  //     navigate('/login');
  //   }
  // }, [isAuthenticated, loading, navigate, location.pathname]);

  useEffect(() => {
    const authRoutes = ['/login', '/forgetPassword', '/otp', '/resetPassword'];
    if (!loading && isAuthenticated && authRoutes.includes(location.pathname)) {
      navigate('/pastAssessment');
    } else if (!loading && !isAuthenticated && !authRoutes.includes(location.pathname)) {
      navigate('/login');
    }
  }, [isAuthenticated, loading, navigate, location.pathname]);

  const login = (token, refreshToken) => {
    try {
      const decodedUser = jwtDecode(token);
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      setIsAuthenticated(true);
      setUser(decodedUser);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const logout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUser(null);
    navigate('/login');
  };

  useEffect(() => {
    setupAxios(logout);
  }, [logout]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
