import React, { useEffect, useState } from 'react';
import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Textarea, Button, UnorderedList, ListItem, Box, useToast } from '@chakra-ui/react';
import { get, patch } from '../../services/api';


const ViewRemarksModal = ({ isOpen, onClose, reportId, type, id, refreshData }) => {
    const toast = useToast();
    const [assertionName, setAssertionName] = React.useState("");
    const [remarks, setRemarks] = useState("");
    const [errors, setErrors] = useState({});
    const handleSubmit = async () => {
        const data = {
            reportId,
            type,
            id,
            remark: []
        };
        try {
            const response = await patch(`report/assessment/${reportId}/remark`, data);

            toast({
                title: "Remark is resolved",
                description: 'Remark is resolved successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            setRemarks([])
            refreshData()
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            if(error.response.status === 400)
            {
                // Show error toast
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
            else if(error.response.status === 404){
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }

        }
    };

    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                try {
                    const endpoint = type === 'strength' ? `keyStrength/${id}` : `keyFinding/${id}`;
                    const response = await get(`report/assessment/${reportId}/${endpoint}`);
                    setAssertionName(response.data.data.heading);
                    setRemarks(response.data.data.remark || [])
                } catch (error) {
                    console.error('Error fetching data', error);
                }
            };

            fetchData();
        }
    }, [isOpen, reportId, type, id]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Assertion
                        </Text>
                        <Textarea
                            px={3}
                            border='1px solid #D1E0FF'
                            focusBorderColor='#2970FF'
                            bg="#F5F8FF"
                            borderRadius='10px'
                            placeholder='Name of an Assertion'
                            size='md' resize={'none'} fontSize="14px"
                            value={assertionName}
                            minHeight='fit-content'
                            lineHeight='21px'
                            isReadOnly
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.assertionName && <Text color="red.500" fontSize="sm">{errors.assertionName}</Text>}
                    </Box>
                    <Box>
                        <Text mb={1} fontWeight="500" fontSize='16px' color="#111927"> Remarks</Text>
                        <Textarea
                            px={3}
                            border='1px solid'
                            borderColor='#D1E0FF'
                            bg='#F5F8FF'
                            borderRadius='10px'
                            focusBorderColor='#2970FF'
                            placeholder='Enter the remark'
                            size='sm' fontSize="14px" resize={'none'}
                            lineHeight='21px'
                            // minHeight='fit-content'
                            value={remarks}
                            isReadOnly
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.remarks && <Text color="red.500" fontSize="sm">{errors.remarks}</Text>}
                    </Box>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button bg="#2970FF" color='white' variant='solid' width='47.5%'  _hover={{ bg: '#7044c4' }} onClick={handleSubmit}>
                        Resolve Remark
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ViewRemarksModal;
