import React, { useEffect, useState } from 'react';
import { Text,Icon, Checkbox, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Textarea, Button, UnorderedList, ListItem, Box, useToast } from '@chakra-ui/react';
import { get, patch } from '../../services/api';
import { CheckIcon } from '@chakra-ui/icons';
import { ReactComponent as minusIcon } from '../../assets/icons/minusIcon.svg';
import { ReactComponent as plusIcon } from '../../assets/icons/plusIcon.svg';


const EditAssertionModal = ({ isOpen, onClose, reportId, type, id, refreshData }) => {
    const toast = useToast();
    const [assertionName, setAssertionName] = React.useState("");
    const [expectedAnswer, setExpectedAnswer] = React.useState("");
    const [initialData, setInitialData] = useState({}); 
    const [accuracy, setAccuracy] = React.useState(0);
    const [errors, setErrors] = useState({});
    const textAreaRef = React.useRef(null);

    // React.useEffect(() => {
    //     if (textAreaRef.current) {
    //         textAreaRef.current.style.height = 'auto';
    //         textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    //     }
    // }, [expectedAnswer]);

    const adjustTextAreaHeight = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustTextAreaHeight();
    }, [expectedAnswer]);

    
    const validate = (field, value) => {
        let error = '';
    
        switch (field) {
            case 'assertionName':
                if (!value) {
                    error = 'Assertion name is required';
                }
                break;
            case 'expectedAnswer':
                if (!value) {
                    error = 'Expected answer is required';
                }
                break;
            default:
                break;
        }
    
        return error;
    };
    
    const handleInputChange = (value) => {
        setExpectedAnswer(value);
        const error = validate('expectedAnswer', value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            expectedAnswer: error
        }));
    };
    
    const handleAssertionNameChange = (value) => {
        setAssertionName(value);
        const error = validate('assertionName', value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            assertionName: error
        }));
    };

    const increaseAccuracy = () => setAccuracy(accuracy + 1);
    const decreaseAccuracy = () => setAccuracy(accuracy - 1);

    const handleSubmit = async () => {

        const sentences = expectedAnswer
        .split('.')
        .map(sentence => sentence.trim())
        .filter(sentence => sentence);
          
        const data = {
            reportId,
            type,
            id,
            heading:assertionName,
            answer: sentences,
            accuracy:accuracy
        };

        console.log(data)

        try {
            const response = await patch(`report/assessment/${reportId}/assertion`, data);

            toast({
                title: response.data.message,
                description: 'Assertion is updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            refreshData()
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            if(error.response.status === 400)
            {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
            else if(error.response.status === 404){
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }

        }
    };

    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                try {
                    const endpoint = type === 'strength' ? `keyStrength/${id}` : `keyFinding/${id}`;
                    const response = await get(`report/assessment/${reportId}/${endpoint}`);
                    // console.log(response.data.data.answer)
                    const formattedAnswer = response.data.data.answer.join('. ');
                    setAssertionName(response.data.data.heading);
                    setExpectedAnswer(formattedAnswer)
                    setAccuracy(response.data.data.accuracy || 0)
                    const fetchedData = {
                        assertionName: response.data.data.heading,
                        expectedAnswer: formattedAnswer,
                        accuracy: response.data.data.accuracy || 0
                    };
                    setInitialData(fetchedData);
                    adjustTextAreaHeight();
                } catch (error) {
                    console.error('Error fetching data', error);
                }
            };

            fetchData();
        }
    }, [isOpen, reportId, type, id]);

    const isDataChanged = () => {
        return (
            assertionName !== initialData.assertionName ||
            expectedAnswer !== initialData.expectedAnswer ||
            accuracy !== initialData.accuracy
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Assertion
                        </Text>
                        <Textarea
                            px={3}
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.assertionName ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            fontSize="14px"
                            borderRadius='10px'
                            placeholder='Name of an Assertion'
                            size='md' resize={'none'}
                            value={assertionName}
                            lineHeight='21px'
                            minHeight='fit-content'
                            onChange={(e) => handleAssertionNameChange(e.target.value)}
                            borderColor={errors.assertionName ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                  color: '#9DA4AE',
                                },
                              }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.assertionName && <Text color="red.500" fontSize="sm">{errors.assertionName}</Text>}
                    </Box>
                    <Box mb="5" >
                        <Box>
                            <Text fontWeight="medium" fontSize='14px' mb="2">
                                Accuracy
                            </Text>
                            <Box display="flex" alignItems="center" justifyContent="space-between" w="fit-content" h="fit-content" p="10px" border="1px solid #D1E0FF" borderRadius="10px" backgroundColor="#F5F8FF">
                                <Box onClick={decreaseAccuracy} border="1px solid #004EEB" cursor="pointer" bg="white" borderRadius="50%" h="32px" w="32px" color="#2563EB" fontWeight="bold" fontSize="20px" display="flex" alignItems="center" justifyContent="center">
                                    <Icon as={minusIcon} width="8px" height="8px" />
                                </Box>
                                <Text mx="3" fontWeight="bold">{accuracy}%</Text>
                                <Box onClick={increaseAccuracy} border="1px solid #004EEB" cursor="pointer" bg="white" borderRadius="50%" h="32px" w="32px" color="#2563EB" fontSize="20px" display="flex" alignItems="center" justifyContent="center">
                                    <Icon as={plusIcon} width="8px" height="8px" />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box mb="5" >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text fontWeight="medium" fontSize='14px' mb="2">
                                Generated Answer
                            </Text>
                        </Box>
                        <Textarea
                            ref={textAreaRef}
                            px={3}
                            border='1px solid #F3F4F6'
                            bg='#F5F8FF'
                            borderRadius='10px'
                            fontSize="14px"
                            focusBorderColor={errors.expectedAnswer ? 'red.500' : '#2970FF'}
                            placeholder='Expected Answer'
                            size='md' resize={'none'}
                            value={expectedAnswer}
                            lineHeight='21px'
                            minHeight='fit-content'
                            textAlign="justify"
                            onChange={(e) => handleInputChange(e.target.value)}
                            borderColor={errors.expectedAnswer ? 'red.500' : '#D1E0FF'}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            sx={{
                                '::placeholder': {
                                    color: '#9DA4AE',
                                },
                            }}
                            css={{
                                '::-webkit-scrollbar': { display: 'none' },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none'
                            }}
                        />
                        {errors.expectedAnswer && <Text color="red.500" fontSize="sm">{errors.expectedAnswer}</Text>}
                    </Box>
                    <Box mb="5" display="flex" alignItems="center">
                        <Checkbox icon={<CheckIcon />} size="md" defaultIsChecked 
                        sx={{
                            ".chakra-checkbox__control": {
                              bg: "white",
                              borderColor: "#2970FF",
                            },
                            ".chakra-checkbox__control[data-checked]": {
                              bg: "#2970FF",
                              borderColor: "#2970FF",
                            },
                            ".chakra-checkbox__control:hover": {
                              bg: "#2970FF",
                              borderColor: "#2970FF",
                            }
                          }}/>
                        <Text ml="2" color="#111927" fontWeight="500" fontSize="14px">Reinforce Changes</Text>
                    </Box>
                    <Text mt="2" fontWeight="400" fontSize="12px" color="#111927">
                        <strong style={{ fontWeight: "500" }}>Note:</strong> By checking this check box all the changes that you made will be reinforced.
                    </Text>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button
                        bg={isDataChanged() ? "#2970FF" : "#F04438"}
                        color='white'
                        variant='solid'
                        width='47.5%'
                        _hover={{ bg: isDataChanged() ? '#7044c4' : '#F04438' }}
                        onClick={handleSubmit}
                        isDisabled={!isDataChanged()}
                    >
                        Save and Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default EditAssertionModal;