import { useEffect, useState } from 'react';
import { get } from '../services/api';

const useIndustryTypes = () => {
  const [industryOptions, setIndustryOptions] = useState([]);

  useEffect(() => {
    const fetchIndustryTypes = async () => {
      try {
        const response = await get('/reportmanagement/industry');
        const industryTypes = response.data.data.map(item => item.industryType);
        setIndustryOptions(industryTypes);
      } catch (err) {
        console.error('Error fetching industry types:', err);
      }
    };

    fetchIndustryTypes();
  }, []);

  return industryOptions ;
};

export default useIndustryTypes;
