import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Spinner } from '@chakra-ui/react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { useNavigate } from 'react-router-dom';
import { post } from '../../services/api';

const PopoverMenu = ({ reportId }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
  
    const viewReport = async (reportId) => {
      navigate(`/reports/${reportId}`);
    };
  
    const downloadReport = async (reportId) => {
        setIsLoading(true);
        try {
            const response = await post(`report/downloadReport/${reportId}`, {}, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_${reportId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading report:', error);
        } finally {
            setIsLoading(false)
        }
    };
  
    return (
        <>
          {isLoading ? (
            <Spinner size="sm" color='#00359E' />
          ) : (
            <Menu size="sm">
              <MenuButton
                as={Button}
                bg="white"
                color="black"
                height="1.2rem"
                _hover={{ bg: "white" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
              >
                <MoreVertRoundedIcon />
              </MenuButton>
              <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                <MenuItem
                  borderRadius='5px'
                  bg="white"
                  _hover={{ bg: "#EFF4FF" }}
                  color='#00359E'
                  onClick={() => viewReport(reportId)}
                >
                  View Report
                </MenuItem>
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                <MenuItem
                  borderRadius='5px'
                  bg="white"
                  _hover={{ bg: "#EFF4FF" }}
                  color='#00359E'
                  onClick={() => downloadReport(reportId)}
                >
                  Download Report
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </>
    );
};

export default PopoverMenu;