import { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const useAdminCheck = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [userId, setUserId] = useState(0);
    const [username, setUsername] = useState('')
    const [companyName, setCompanyName] =  useState('')
  
    useEffect(() => {
      const token = localStorage.getItem('refreshToken');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          setIsAdmin(decodedToken.type === 'Admin');
          const id = parseInt(decodedToken.id, 10);
          setUserId(id)
          setUsername(decodedToken.name)
          setCompanyName(decodedToken.companyName)
        } catch (error) {
          console.error("Invalid token", error);
        }
      }
    }, []);
  
    return { isAdmin, userId, username, companyName };
};
  
  export default useAdminCheck;
